.title-box{
    @media (max-width:1200px) {
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
    }
    @media (max-width:567px) {
        padding: 0 22px;
    }
}
.portfolioPage{
    main{
        padding-bottom: 0;
    }
    .container{
        @media (min-width:568px) and (max-width:992px) {
            padding: 0 25px;
        }
    }
   
    .portfolio-box{
        display: flex;
       
        @media  (min-width: 1200px){
            margin-top: 200px;
        }
        // justify-content: flex-end;
        @media (max-width:1200px) {
            display: block;
        }
        @media (max-width:567px) {
            padding: 0 22px;
        }
    }
    .shareh2{
        @media  (min-width: 1200px){
            position: fixed;
        }
    }
    .item_menu_Box {
        .shareh2{
            position: relative;
            display: none;
            left: -5px;
        }
    }
    .top-menu-ul{
        margin-right: 265px;
        position: relative;
        opacity: 1;
        transition: .4s;
        letter-spacing: 0;
        @media  (min-width: 1200px){
            position: fixed;
            top: 362px;
            margin-left: 5px;
            li{
                position: relative;
                top: -5px;
                margin-bottom: 30px;
                .active{
                    animation: none !important;
                }
            }
        }
    }
    .right-box{
        width: 772px; 
        min-height: 432px;
        @media  (min-width: 1200px){
            position: relative;
            left: 34%;
            
        }
        
        @media  (max-width: 1200px){
            max-width: 772px; 
            width: 100%;
            margin: 0 auto;
        }
        .pc-surbox{
            position: relative;
            opacity: 0;
            margin-bottom: 100px;
            top:10px;
            transition: all 0.6s;
            @media (min-height:768px) {
                &:nth-child(3){
                    transition-delay: .2s;
                }
                &:nth-child(4){
                    transition-delay: .2s;
                }
            }
            @media (max-width:768px) {
                &:nth-child(even){
                    transition-delay: .2s;
                }
            }
           
            &.is-active{
                opacity: 1;
                top:0;
            }
            @media  (max-width: 768px) {
                &:not(:last-child){
                    margin-bottom: 20px;
                }
            }
            // &:first-child{
            //     animation: fade1 .4s .4s linear forwards;
            // }
            // &:nth-child(2){
            //     animation: fade1 .4s .8s linear forwards;
            // }
            @keyframes fade1 {
                0%{
                    opacity: 0;
                    top: 10px;
                }
                100%{
                    top: 0;
                    opacity:1;
                }
            }
            a{
                display: block;
                text-decoration: none;
                @media (min-width:992px) {
                    &:hover{
                        .sur{
                        opacity: .5;
                        }
                    }
                }
            }
        }
        .sur{
            background: url(../images/backimg.png) no-repeat ; 
            background-size: cover;
            padding-bottom: 50%;
            height: 0;
            width: 100%;
            position: relative;
            transition: .4s;
            cursor: pointer;
            
           
        }
        .item-product{
            width: 100%;
            height: 100%;
            position: absolute;
        }
        .h-textbox{
            color: #000000;
            font-size: pxToEm(15);
            line-height: 2;
            font-family: $font_content;
            margin-top: 16px;
            letter-spacing: 0;
            font-weight: 400;
            @media (max-width:768px) {
                font-size: pxToEm(14);
            }
            @media (max-width:567px) {
                margin-top: 5px;
            }
        }
    }
    @media (max-width:1200px) {
        .left-line{
            border-left:none;
            
            &::before{
                position: absolute;
                content: '';
                width: 3px;
                height: 27px;
                background:none;
                top: 0;
                left: -4px;
            }
            @media (max-width:768px) {
                &::before{
                    width: 2px;
                    height: 17px;
                    left : -3px;
                }
            }
        }
    }
    #top-menu-ul {
        @media (max-width:768px) {
            margin-bottom: 40px !important;
        }
    }
}
.portfinsidePage{
    @media (min-width: 1200px){
        .shareh2 {
        position: relative;
            }
        .portfolio-box {
                margin-top: 0px;
            }
    }
    .container{
        @media (min-width:568px) and (max-width:992px) {
            padding: 0 25px;
        }
    }
    .editor_Content{
        margin-bottom: 100px;
    }
    .portfolio-box{
        justify-content: center;
        display: flex;
        position: relative;
        opacity: 0;
        animation: porimgfade .4s linear forwards;
        @media (max-width:1200px) {
            text-align: center;
        }
        img{
            margin-bottom: 45px;
        }
        @media (max-width:567px) {
            padding: 0 22px;
        }
    }     
    .inside-title{
        position: relative;
        opacity: 0;
        animation: porimgfade .4s .4s linear forwards;
    }
    @keyframes porimgfade {
        0%{
            opacity: 0;
            top: 5px;
        }
        100%{
            opacity: 1;
            top: 0;
        }
    }
   
    .editor_Content{
        padding-top: 40px;
        border-top: 1px solid #dcdcdc;
        line-height: 2;
        letter-spacing: 0;
    }
    @media (max-width:1200px) {
        .title-box{
            max-width: 1000px;
        }
        .shareh2{
            margin: 140px 0 50px;
        }
    }
    .arr_btn{
        &::before{
            background: #575757;
            bottom: 0px;
            left: 58px;
            width: 47px;
        }
        &::after{
            background: #575757;
            bottom: 6px;
            right: 0px;        
        }
        @media (max-width:768px) {
            &::before{
                width: 35px;
                left: 50px;
            }
            &::after{
                bottom: 5px;
            }
        }
        @media (min-width:768px) {
            &:hover{
                &::before{
                    width: 57px;
                }
                &::after{
                    right: -10px;  
                }
            }
        }
       
    }
}
.inside-title{
    color: #000000;
    font-family: $font_content;
    letter-spacing: 0;
    position: relative;
    padding-left: 15px;
    margin-bottom: 40px;
    margin-left: 2px;
    &:after{
        position: absolute;
        content: '';
        bottom: 0;
        left: -1px;
        background: #ffffff;
        width: 5px;
        height: 3px;
    }
    @media (min-width:768px) {
        margin-left: 3px;
    }
    div{
        font-size: pxToEm(16);
        line-height: 2.16;
        position: relative;
        top: -5px;
        @media (max-width:768px) {
            font-size: pxToEm(14);
        }
    }
    h3{
        font-size: pxToEm(36);
        line-height: 0.83;
        font-weight: 400;
        @media (max-width:768px) {
            font-size: pxToEm(20);
        }
    }
    
    
}
.inside-box{
    padding: 0 15px;
    @media (max-width:1200px) {
        width: 100%;     
        max-width: 1000px;
        flex: 0 0 100%;
        margin: 0 auto;
    }
    @media (max-width:567px) {
        padding: 0 22px;
    }
}
.back{
    position: relative;
    letter-spacing: 0.9px;
    font-size: pxToEm(18);
    width: 108px;
    a{
        color: #575757;
        text-decoration: none;
        display: block;
        width: 112px;
        position: relative;
    }
    @media (max-width:768px) {
        width: 87px;
        font-size: pxToEm(16);
    }
}

.articleinsidePage{
    .editor_Content{
        margin-bottom: 100px;
        color: #000000;
        padding-top: 0px;
    }
    .inside-title{
        position: relative;
        opacity: 0;
        animation: insidefade .4s linear forwards;
        @keyframes insidefade {
            0%{
                opacity: 0;
                top: 10px
            }
            100%{
                opacity: 1;
                top: 0;
            }
        }
        h3{
            font-size: pxToEm(20);
            font-weight: 400;
            @media (max-width:768px) {
                font-size: pxToEm(17);
            }
        }
        .inside-nct{
            margin-left: 10px;
            padding-left: 10px;
            position: relative;
            &::before{
                width: 1px;
                height: 17px;
                background: #000000;
                position: absolute;
                content: '';
                top: 4px;
                left: 0px;
            }
        }
    }
    .editor_Content{
        border-top: none;
        line-height: 2;
        letter-spacing: 0;
    }

}
.articlePage{
    .container{
        padding: 0 22px;
    }
    @media (max-width:1200px) {
        .shareh2 {
            margin: 140px 0 50px;
        }
        #top-menu-ul {
            margin-bottom: 35px!important;
        }
    }
    @media (max-width:768px) {
        .shareh2{
            font-size: pxToEm(24);
        } 
        #top-menu-ul {
            margin-bottom: 50px!important;
        }
    }
    
    main{
        padding-bottom: 0px;
    }
  
    .right-box{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 402px;
        @media (min-width:1200px) {
            // top: 12.5%;
            margin-top: 160px;
        }
    }
    .pc-surbox{
        width: calc((100% - 30px) / 2);
        margin-bottom: 70px !important;
        @media (min-width:769px) {
            &:hover{
                .sur{
                    opacity: .5;
                }
            }    
        }
        
        @media (max-width:768px) {
            width:100%;
            margin-bottom: 0px !important;
        }
        a{
            text-decoration: none;
        }
        .sur{
            padding-bottom: 65%;
            background-position: center;
        }
        .h-textbox{
            div{
                line-height: 1.85;
                letter-spacing: 0;
            }
            .text-bx{
                height: 48px;
                overflow: hidden;
                font-weight:400;
                @media (max-width:768px) {
                    margin-bottom: 17px;
                }
            }
        }
    }
    @media (min-width: 1200px){
        .stopmenu .top-menu-ul {
            bottom: 30px;
        }
    }   

}

.contactPage{
    .right-box{
    }
    .con-h2{
        @media (max-width:768px) {
            margin-left: 10px;
        }
    }
    .left-text{
        width: 245px;
        height: 88px;
        font-size: pxToEm(15);
        font-family: $font_content;
        line-height: 2;
        letter-spacing: 0;
        margin-top: 15px;
        &::after{
            height: 13px;
        }
        br{
            display: none;
        }
        p{
            position: relative;
            top: -8px;
            font-weight: 400;
        }
        // @media (max-width:1200px) {
        //     margin-left: 10px;
        // }
        
        @media (max-width:768px) {
            height: auto;
            width: 93%;
            margin-left: 10px;
            margin-bottom: 60px;
            br{
                display: block;
            }
            p{
                position: relative;
                top: -10px;
            }
            &::after{
                height: 20px;
            }
        }
    }
    .per-card{
        font-family: $font_content;
        font-size: pxToEm(15);
        font-weight: 300;
        line-height: 2.8;
        letter-spacing: 0.75px;
        color: #000000;
        @media (max-width:768px) {
            font-size: pxToEm(14);
            margin-bottom: 50px;
        }
        a{
            color: #000000;
            text-decoration: none;
            margin-left: 10px;
            @media (max-width:768px) {
                position: relative;
                top: 3px;
            }
            &:nth-child(3){
                margin-left: 11px;
            }
        }
        .mailml{
            margin-left: 11px;
        }
        span{
            margin-left: 6px;
            line-height: 2;
            @media (max-width:768px) {
                position: relative;
                top: 3px;
            }
            
        }
        .loml{
            margin-left: 31px;
            line-height: 1.8;
            display: block;
            width: 335px;
            @media (max-width:768px) {
               letter-spacing: 0;
            }
            @media (max-width:576px) {
                width: auto;
            }
        }
        .map-img{
            display: flex;
            justify-content: center;
        }
        ul li:last-child{
            line-height: 2;
            margin-top: 5px;
          
        }
    }
    .right-tbox{
        margin-bottom: 50px;
        @media (max-width:768px) {
            margin-bottom: 60px; 
        }
    }
    .right-bbox{
        padding-top: 60px;
        border-top: 1px solid #dcdcdc;
        form{
            color: #000000;
            font-size: pxToEm(15);
            font-family: $font_content;
            letter-spacing: 0.75px;
            input{
                border-bottom: 1px solid #dcdcdc;
                height: 30px;
                border-radius: 0px;
                padding: 0 0;
                margin-left: 6px;
                margin-top: 5px;
                @media (max-width:567px) {
                margin-top: 15px;  
                }
                &:focus{
                    border-bottom: 1px solid #dcdcdc;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                }
            }
            .note{
                color: $hover;
            }
            // textarea{
            //     border-bottom: 1px solid #dcdcdc;
            //     min-height: 182px;
            //     border-radius: 0px;
            //     margin-top: 1px;
            //     overflow: hidden;
            //     // @media (max-width:768px) {
            //     //     height: 125px;
            //     // }
            //     &:focus{
            //         border-bottom: 1px solid #dcdcdc;
            //         border-top: none;
            //         border-left: none;
            //         border-right: none;
            //     }
            // }
            .textarea-wrapper{
                height: 182px;
                overflow: hidden;
                border-bottom: solid 1px rgba(#878686,0.5);
                padding:0px 0px 10px;
                margin-top: 15px;
                textarea{
                    min-height: 169px;
                    height: 0;
                    overflow: hidden;
                    border-bottom:none;
                    padding:0;
                    text-rendering: auto;
                    -webkit-writing-mode: horizontal-tb !important;
                    cursor:text;
                    &:focus{
                                border: none ;
                            }
                }
            }
            .form-group{
                margin-bottom: 0;
                .ml{
                    margin-left: 7px;
                }
            }
            .help-block{
                height: 26px;
                color: #e41b5b;
                margin-top: 4px;
                font-size: pxToEm(11);
                letter-spacing: 0.83px;
                font-family: $font_content;
                padding: 0 5px;
            }
            .form-box{
                margin-bottom: 40px;
            }
            .arr_btn{
                @media (min-width:992px) {
                    &:hover{
                        &::before{
                            right: -18px;
                            width: 64px;;
                        }
                    }
                }
                &::before{
                    background: #000000;
                    right: 0;
                    left: auto;
                    width: 46px;
                    @media (max-width:768px) {
                        width: 36px;
                    }
                }
                &::after{
                    background: #000000;
                    width: 12px;
                    bottom: 4px;
                }
            }
            .send-btn{
                background: #ffffff;
                border: none;
                position: relative;
                line-height: 0.8;
                margin-bottom: 95px;
                margin-top: 40px;
                padding: 0 15px;
                font-size: pxToEm(18);
                font-family: $font_content;
                color: #575757;
                cursor: pointer;
                width: 115px;
                text-align: left;
                font-weight: 300;
                &:focus{
                    outline: none;
                }
                @media (max-width:768px) {
                    width: 105px;
                    font-size: pxToEm(16);
                    margin-bottom: 20px;
                    margin-top: 10px;
                    padding: 0 15px;
                }
            }
            
        }
    }
}

.mCSB_dragger{
    background: #dcdcdc;
    border-radius: 99px;
}
.mCSB_dragger_bar{
    background-color: #dcdcdc !important;
}
.mCSB_scrollTools{
    width: 8px !important;
}
.mCSB_scrollTools .mCSB_draggerRail{
    background-color: #dcdcdc !important;
    width: 1px !important;
}