/*----------------------header------------------------*/

.header_show {
    transition: .4S;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(#131314,0);
    z-index: 10;
    li {
        list-style: none;
    }
    &.active{
        background: rgba(#131314,1);
    }
}

.header_box {
    position: relative;
    width: 100%;
    height: 79px;
    padding: 0 2vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: pxToEm(14);
    background: white;
    top: -106px;
    transition: .4s;
    
}
.m-nav{
    animation: header-inshow .6s linear forwards;
}
@keyframes header-inshow {
    0%{
        top:-106px
    }
    100%{
        top: 0;
    }
}



.logo {
    width: 220px;
    height: 39px;
    background: url(../svg/gogod-logo.svg) top left no-repeat;
    background-size: contain;
    text-indent: -10000px;
    a {
        @include aLink();
    }
}


/*----------------------nav------------------------*/

.nav_box {
    position: relative;
  
    ul {
        display: flex;
        font-weight: 400;
        li{
            position: relative;
            text-align: center;
            @media (max-width:768px) {
                color: #fff !important;
            }
            @media (min-width:992px) {
                &:hover{
                    a{
                    color: $hover;
                    }
                }
            }
        }
        li:not(:last-child){
            margin-right: 50px;
        }
    }
    a{
        width: 100%;
        display: block;
        color: #000000;
        text-decoration: none;
        font-family: $font_racontent;
        font-size: pxToEm(16);
        &:after{
            transition: .4s;
            position: relative;
            content:'';
            width: 0;
            height: 2px;
            background: $blue;
            bottom: -26px;
            left: 8px;
            opacity: 0;
        }
        &:hover{
            &:after{
                width: 77%;
                opacity: 1;
            }
            .en{
                opacity: 0;
            }
            .tw{
                opacity: 1;
            }
        }
    }
}


/*----------------------nav icon------------------------*/

.menu-wrapper {
    display: none;
}

$bar-width: 31px;
$bar-height: 2px;
$bar-spacing: 10px;
$bar-color:#fff;
.menu-wrapper {
    display: none;
    position: absolute;
    top: 25px;
    right: 25px;
    bottom: 0;
    margin: auto;
    width: $bar-width;
    height: 37px;
    cursor: pointer;
    z-index: 50;
}

.hamburger-menu:after,
.hamburger-menu:before {
    width: 27px;
    height: $bar-height;
    @include border();
}

.hamburger-menu {
    position: relative;
    transform: translateY($bar-spacing);
    background: #bfbfbf;
    // transition: all 0ms 300ms;
    // @include border(99px);
    // &.animate {
    //     background: rgba(255, 255, 255, 0);
    // }
}

.hamburger-menu:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: $bar-spacing;
    background: #bfbfbf;
    // transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

.hamburger-menu:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: #bfbfbf;
    transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
    @include border(99px);
}

// .hamburger-menu.animate:after {
//     top: 0;
//     transform: rotate(45deg);
//     transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
//     ;
//     @include border(99px);
// }

// .hamburger-menu.animate:before {
//     bottom: 0;
//     transform: rotate(-45deg);
//     transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
//     ;
//     @include border(99px);
// }

//------------------------- Menu icon -------------------------//
.menu-icon {
    display: none;
    top: 45%;
    right: -0.7em;
    z-index: 100;
    width: 45px;
    height: 39px;
    margin-top: 0;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    position: absolute;
    @media (max-width: $breakpoint-tablet) {
        display: block;
    }
}

//------------------------- Mobie Menu icon -------------------------//
.menu-icon-block {
    display: none;
    position: relative;
    height: 100%;
    margin: auto;
    i {
        position: absolute;
        display: block;
        margin: auto;
        width: 72%;
        height: 2px;
        background-color: $white;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-border-radius: 99em;
        -moz-border-radius: 99em;
        border-radius: 99em;
        &.i1 {
            top: 0%;
        }
        &.i2 {
            top: 25%;
        }
        &.i3 {
            top: 51%;
        }
    }
}




@include media-breakpoint-down(md){
    $header_heright: 70px;

    .logo{
        width: 170px;
        height: 34px;
    }
    // .header_show{
    //     background: $bla;
    // }
    .header_box {
        width: 100%;
        height:  $header_heright;
        font-size: pxToEm(17);
        padding: 0 25px;
    }
    .nav_box nav{
        width: 90%;
        margin: auto;
    }
    .nav_box {
        display: none;
        position: fixed;
        top:  69px;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 11;
        background: #ca275f;
        padding-top: 20px;
        ul {
            display: block;
            li {
                width: 100%;
                margin-bottom: 0;
                a {
                    padding: 30px 15px;
                    text-align: center;
                    text-decoration: none;
                    color: #fff;
                }
                .active{
                    color: #ffffff !important;
                }
            }
        }
        ul>li:not(:last-child) {
            margin-right: 0;
        }

    }
    .menu-wrapper {
        display: block;
    }

}


// index-header

.ch-nav{
    background: rgba(0, 0, 0, 0);
    // animation: chnav .4s linear forwards;
    top: 0;
    .logo{
        background: url(../svg/index-logo.svg) no-repeat;
        z-index: 50;
    }
    .nav_box{
        margin-top: 50px;
        position: absolute;
        top: 0px;
        right: -5%;
        animation: chnav .4s linear forwards;
        @media (max-width:992px) {
            margin-top: 0;
            padding-top: 80px;
        }
    }
    .nav_box ul{
        display: block;
    }
    .nav_box ul li{
        text-align: end;
    } 
    .nav_box ul li:not(:last-child){
        margin-right: 0;
    }
    .nav_box li{
        margin-bottom: 50px;
        position: relative;
        @media (max-width:992px) {
            margin-bottom: 0px;
        }
        &::before{
            position: absolute;
            content: '';
            top: 50%;
            transform: translateY(-50%);
            left: 110%;
            width: 0px;
            height: 1px;
            background: white;
            transition: all .4s;
        }
        @media (min-width:769px) {
            &:hover{
                color: #fff;
                &::before{
                    width: 30px;
                }
            }
        }
        .active{
            
        }
            
    }
    .nav_box a{
        color: #fff;
        @media (min-width:769px) {
            &:hover{
                color: #fff;
            }
        }
       
    }
    .hamburger-menu:after{
        background: #fff;
    }
    .hamburger-menu:before{
        background: #fff;
    }
}
@keyframes chnav {
    0%{
        right: -5%;
    }
    100%{
        right: 2%;
    }   
}
.open{
    background: #ca275f;
    .hamburger-menu:after{
        background: #fff;
    }
    .hamburger-menu:before{
        background: #fff;
    }
    .logo{
        background: url(../svg/index-logo.svg) no-repeat;
    }
}

.animate{
    &::before{
        animation: humanibe .4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
    }
    &::after{
        animation: humaniaf .4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
    }
    @keyframes humanibe {
        0%{
            transform: rotate(0deg);
            bottom: 10px
        }
        100%{
            transform: rotate(405deg);
            bottom:3px
        }
    }
    @keyframes humaniaf {
        0%{
            transform: rotate(0deg);
            top:0
        }
        50%{
            transform: rotate(25deg);
            top: 0;
        }
        100%{
            transform: rotate(-45deg);
            top: -5px;
        }
    }
}
.colsanimate{
    &::before{
        animation: colshumbf .4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
    }
    &::after{
        animation: colshumaf .4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
    }
    @keyframes colshumbf {
        0%{
            bottom:3px;
            transform: rotate(405deg);
        }
        100%{
            bottom: 10px;
            transform: rotate(0deg);
        }
    }
    @keyframes colshumaf {
        0%{
            transform: rotate(-45deg);
            top: -5px;
        }
        100%{
            transform: rotate(0deg);
            top:0;
        }
    }
}