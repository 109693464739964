.indexPage{
    .index-banner{
        position: relative;
    }
    .pc-banner{
        height: 100vh;
        transition: background 2s;
        @media (max-width:992px) {
            display: none;
        }
        .i1{
            // background-color: #ca275f;
            .box1{
                top: 28.5%;
                left: 43.5%;            
                .arr_btn{
                    right: 0;
                    bottom: -71px;
                }
            }
        }
        .i2{
            // background-color: #5c95b3;
            display: none;
            .box1{
                top: 28.5%;
                left: 54.5%;            
                .arr_btn{
                    right: 8px;
                    bottom: -72px;
                }
            }
        }
        .i3{
            // background-color: #48b9b7;
            display: none;
            .box1{
                top: 29.5%;
                left: 26%;            
                .arr_btn{
                    right: -1px;
                    bottom: -67px;
                }
            }
        }
        .i4{
            // background-color: #ffba00;
            display: none;
            .box1{
                top: 29%;
                left: 31%;      
                .arr_btn{
                    right: 0px;
                    bottom: -71px;                
                }
            }
        }
        .banner-box{
            position: absolute;
            h2{
                font-size: pxToEm(62);
                line-height: 1.35;
                letter-spacing: 3.1px;
                font-family: $font_racontent;
                color: #fff;
                @media (max-width:768px) {
                    font-size: pxToEm(28);
                }
            }
            a{
                position: absolute;
            }
            span{
                margin-left: 18px;
            }
        }
    }
    .pc-banner .it {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        .it-bx{
            position: absolute;
            left: 50%;
            bottom: 0;
            width: 885px;
            transform: translateX(-50%);
            transition: .4s;
            img{
                opacity: 0;
                transition: .4s;
            }
            .box1{
                opacity: 0;
                transition: .4s;
            }
        }
    }
    // pc banner end
    .show-me{
            &:before{
                height: 3px !important;
                background: #fff !important;
            }
    }
    .show-box{
        position: absolute;
        bottom: 64px;
        left: 50%;
        z-index: 10;
        transform: translateX(-50%);
        display: flex;
        @media (max-width:992px) {
            bottom: 20px;
        }
        .go-it{
            width: 38px;
            height: 15px;
            margin: 0 3px;
            cursor: pointer;
            position: relative;
            &:before{
                width: 38px;
                height: 1px;
                background: #fff;
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }
    .chbanner{
        .it-bx img{
            animation: imgfain .5s .7s linear forwards ;
        }
        .it-bx .box1{
            animation: imgfain .5s 1.2s linear forwards ;
        }
    }
    @keyframes imgfain {
        0%{
            opacity: 0;
            // top: -3px;
        }
        100%{
            opacity: 1;
            // top: 0;
        }
    }
    // mo banner 
    .mo-banner{
        height: 100vh;
        display: none;
        transition: 2s;
        @media (max-width:992px) {
            display: block;
            height: 48vh;
        }
        @media (max-width:768px) {
            height: 48vh;
        }
        .banner-box{
            position: absolute;
            h2{
                font-size: pxToEm(27);
                line-height: 1.35;
                letter-spacing: 3.1px;
                font-family: $font_racontent;
                color: #fff;
                @media (max-width:768px) {
                    letter-spacing: 0px;
                }
            }
            a{
                position: absolute;
            }
            span{
                margin-left: 18px;
                @media (max-width:768px) {
                    margin-left: 0px;
                    display: block;
                }
            }
        }
        .it{
            width: 100vw;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 0;
            @media (max-width:992px) {
                height: 48vh;
            }
            .it-bx{
                position: absolute;
                left: 50%;
                bottom: 0;
                width: 668px;
                transform: translateX(-50%);
                @media (max-width:992px) {
                    width: 450px;
                }

                @media (max-width:576px) {
                    width: 330px;
                }
                @media (max-width:330px) {
                    width: 280px;
                }
                img{
                    opacity: 0;
                    transition: .4s;
                }
                .box1{
                    opacity: 0;
                    transition: .4s;
                }
            }
        }
        .t1{
            // background-color: #ca275f;
            .box1{
                top: 28%;
                right: 25%;
                @media (max-width:576px) {
                    right: 32%;
                }
            }
            .arr_btn{
                right: 0px;
                bottom: -30px;                
            }
        }
        .t2{
            display: none;
            // background-color: #5c95b3;
            .box1{
                top: 131px;
                right: 40px;
                @media (max-width:992px) {
                    top: 90px;
                    right: 43px;                
                }
                @media (max-width:576px) {
                    top: 55px;
                    right: 25px;                
                }
            }
            .arr_btn{
                right: 0px;
                bottom: -30px;  
                @media (max-width:992px) {
                    right: 2px;                
                } 
                @media (max-width:576px) {
                    bottom: -20px;                
                }             
            }
        }
        .t3{
            display: none;
            // background-color: #48b9b7;
            .box1{
                top: 135px;
                left: 50%;
                transform: translateX(-50%);
                @media (max-width:992px) {
                    top: 85px;
                }
                @media (max-width:576px) {
                    top: 45px;
                    right: 25px;   
                    width: 100%;
                    text-align: center;             
                }
            }
            .arr_btn{
                // right: 5px;
                bottom: -30px;  
                left: 50%;
                transform: translateX(-42%);              
            }
        }
        .t4{
            display: none;
            // background-color: #ffba00;
            .box1{
                top: 135px;
                left: 50%;
                transform: translateX(-50%);
                @media (max-width:992px) {
                    top: 85px;
                }
                @media (max-width:576px) {
                    top: 55px;
                }
            }
            .arr_btn{
                right: 0px;
                bottom: -30px;                
            }
        } 
    }
    // mo banner end
    .ind-bgv2{
        background: #fcfaf6;
    }
    .ind-bg{
        background: #f6f6f6;
    }
    .item_box{
        display: flex;
        margin-top: 198px;
        @media (max-width:992px) {
            display: block;
            margin-top: 55px;
            padding: 0 25px
        }
        @media (max-width:768px) {
            margin-top: 55px;
        }
    }
    // .item_box:first-child{
    //     @media (max-width:992px) {
            
    //     }
    // }
    .in-ltitle{
        font-size: pxToEm(36);
        line-height: 0.32;
        letter-spacing: 1.8px;
        color: #000000;
        font-family: $font_racontent;
        padding-top: 9px;
        opacity: 0;
        position: relative;
        @media (max-width:992px) {
            margin-bottom: 50px;
            font-size: pxToEm(23);
        }
        @media (max-width:768px) {
            margin-bottom: 50px;
        }
    }
    .ab-box{
        padding-bottom: 80px;
        padding-left: 55px;
        color: #000000;
        font-family: $font_content;
        width: 815px;
        max-width: 77%;
        font-size: pxToEm(15);
        line-height: 2;
        letter-spacing: 0;
        position: relative;
        margin-left: 58px;
        opacity: 0;
        font-weight: 300;
        &::after{
            background: #f6f6f6;
        }
        @media (max-width:992px) {
            margin-left: 0px;
            max-width: 100%;
        }
        @media (max-width:768px) {
            margin-left: 0px;
            max-width: 100%;
            padding-left: 15px;
            padding-bottom: 40px;
        }
        .ch-text{
            margin-bottom: 32px;
            position: relative;
            top: -9px;
            @media (max-width:768px) {
                font-size: pxToEm(14);
            }
        }
        .en-text{
            @media (max-width:768px) {
                font-size: pxToEm(14);
            }
        }
        .arr-box{
            position: absolute;
            bottom: 4px;
            .arr_btn::before{
                background: #bfbfbf;
            }
            .arr_btn::after{
                background: #bfbfbf;
            }
            @media (max-width:567px) {
                left: -2px;
            }
        }
    }
    .pro-item{
        margin-top: 90px;
        padding-bottom: 235px;
        @media (max-width:992px) {
            margin-top: 90px;
            padding-bottom: 43px;
        }
    }
    .pro-box{
        // padding-left: 65px;
        position: relative;
        opacity: 0;
        width: 775px;
        max-width: 76%;
        margin-left: 21px;
        padding: 130px 0 130px 65px;
        &::after{
            background: #f6f6f6;
        }
        @media (max-width:992px) {
            max-width: 100%;
            margin-left: 0;
        }
        @media (max-width:768px) {
            padding: 0 60px 130px 60px;
            display: flex;
            justify-content: center;
        }
        @media (max-width:576px) {
            padding: 0 70px 65px 70px;
        }
        @media (max-width:350px) {
            padding: 0 30px 65px 30px;
        }
        .arr-box{
            position: absolute;
            bottom: 4px;
            @media (max-width:768px) {
                left: 0;
            }
            .arr_btn::before{
                background: #bfbfbf;
            }
            .arr_btn::after{
                background: #bfbfbf;
            }
        }
        .pc-proimg{
            display: block;
            @media (max-width:768px) {
                display: none;
            }
        }
        .mo-proimg{
            height: auto;
            width: 100%;
            @media (min-width:769px) {
                display: none;
                
            }
        }
    }
    .in-bg{
        background: #fff;
    }
    .sur-bx{
        justify-content: space-between;
        align-items: center;
        margin-top: 90px;
        margin-bottom: 90px;
        position: relative;
        opacity: 0;
        @media (min-width:992px) {
            max-width: 1050px;
            width: 100%;
            margin: 0 auto;
            margin-top: 90px;
            margin-bottom: 90px;
        }
        @media (max-width:992px) {
            display: flex;
            flex-wrap: wrap;
        }
        @media (max-width:576px) {
           margin-top: 70px;
           margin-bottom: 85px;
        }
        .item-product{
            width: 100%;
            height: 100%;
            position: absolute;
        }
        .sur{
            // background: #6c2727;
            padding-bottom: 65%;
            height: 0;
            width: 100%;
            // max-width: 250px;
            position: relative;
            @media (min-width:768px) {
                &:hover{
                    .h-textbox{
                        opacity: 1;
                    }
                }
            }
        }
        .sur2{
            // background: #6c2727;
            padding-bottom: 123%;
            height: 0;
            width: 100%;
            // max-width: 250px;
            position: relative;
            @media (min-width:768px) {
                &:hover{
                    .h-textbox{
                        opacity: 1;
                    }
                }
            }
        }
        .pc-surbox{
            width: calc((100% - 30px) / 4);
            @media (max-width:992px) {
                width: calc((100% - 30px) / 2);
            }
            @media (max-width:768px) {
                width: 100%;
                margin: 12px 0 ;
            }
            .h-textbox{
                background: rgba(0,0,0,.75);
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 5;
                transition: .4s;
                opacity: 0;
                font-weight: 300;
                font-family: $font_racontent;
                @media (max-width:992px) {
                    opacity: 1;
                }
                div{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    font-size: pxToEm(18);
                    color: #fff;
                    text-decoration: none;
                    text-align: center;
                    width: 100%;
                    @media (max-width:768px) {
                        font-size: pxToEm(14);
                    }
                }
            }
        }
        .martb{
            margin: 14px 0 ;
            @media (max-width:768px) {
                margin: 0 0 ;
            }
        }
        .mo-sur{
            display: none;
            @media (max-width:992px) {
                display: block;
            }
        }
        .pc-sur{
            display: block;
            @media (max-width:992px) {
                display: none;
            }
        }
    }
    .con-bx{
        margin-top: 68px;
        margin-bottom: 60px;
        position: relative;
        text-align: center;
        opacity: 0;
        @media (max-width:768px) {
            margin-top: 45px;
            margin-bottom: 30px;
            top: 15px;        
        }
        h2{
        font-size: pxToEm(36);
        line-height: 0.32;
        letter-spacing: 1.8px;
        font-family: $font_racontent;
        margin-bottom: 28px;
        color: #010101;
        @media (max-width:768px) {
            font-size: pxToEm(23);
            line-height: 0.25;
        }
        }
        p{
            font-size: pxToEm(15);
            line-height: 2;
            letter-spacing: 0.75px;
            font-family: $font_racontent;
            color: #000000;
            @media (max-width:768px) {
                font-size: pxToEm(14);
                line-height: 2;
            }
        }
        .arr_btn:before{
            background:  #ac9f83;
        }
        .arr_btn:after{
            background:  #ac9f83;
        }
        .arr-box{
            position: absolute;
            bottom: -49px;
            left: 50%;
            transform: translatex(-50%);
            @media (max-width:768px) {
                bottom: -31px;
                left: 47%;
            }
        }

    }

}
@media (min-width:1200px) {
    .container{
        max-width: 1200px;
    }
}



.aboutPage{
    
    h2 {
        font-size: pxToEm(36);
        line-height: 0.32;
        letter-spacing: 1.8px;
        font-family: $font_racontent;
        margin: 210px 0 125px 0  ;
        color: #000000;
        @media (max-width:1200px) {
            margin: 140px 0 50px 25px  ;
        }
        @media (max-width:768px) {
            margin: 140px 0 50px 25px ;
            font-size: pxToEm(22);
        }
    }
    .about-box{
        display: flex;
        position: relative;
        @media (max-width:1200px) {
            display: block;
        }
    }
    .left-text{
        font-size: pxToEm(15);
        line-height: 2;
        color: #000000;
        padding-left: 18px;
        position: relative;
        margin-right: 147px;
        height: 270px;
        width: 265px;
        opacity: 0;
        &::after{
            height: 8px;
        }
        @media (max-width:1200px) {
            margin-bottom: 90px;
            margin-right: 0px;
            margin-left: 25px;
            font-size: pxToEm(14);
        }
        @media (max-width:768px) {
            margin-bottom: 70px;
            &:after{
                position: absolute;
                content: '';
                width: 5px;
                height: 25px;
                background: #ffffff;
                left: -3px;
                bottom: -1px;
            }
        }
        @media (max-width:576px) {
            margin-bottom: 70px;
            margin-left: 10px;
            padding-left: 15px;
            width: 260px;
        }
    }
    .ch-box{
        font-size: pxToEm(15);
        margin-bottom: 29px;
        position: relative;
        top: -6px;
        font-weight: 500;
        letter-spacing: 0;
        @media (max-width:768px) {
            font-size: pxToEm(14);
            margin-bottom: 25px;
        }
        @media (max-width:567px) {
            font-weight: 500;
        }
        
    }
    .en-box{
        font-size: pxToEm(15);
        letter-spacing: 0;
        @media (max-width:768px) {
            font-size: pxToEm(14);
        }
    }
    .ab-img{
        display: flex;
        margin-bottom: 80px;
        @media (max-width:768px) {
            display: block;
            margin-bottom: 70px;
         
        }

    }
    .right-box{
        width: 769px;
        position: relative;
        opacity: 0;
        @media (max-width:1200px) {
           
            width: 100%;
            padding: 0 25px;
        }
        @media (max-width:768px) {
            max-width: 769px;
            padding: 0 10px;
        }
    }
    .img-card{
        display: flex;
        &:not(:last-child){
            margin-right: 110px;
        }
   
        div{
            text-align: center;
            &:not(:last-child){
                margin-right: 110px;
            }
            @media (max-width:992px) {
                &:not(:last-child){
                    margin-right: 100px;
                }
            }
            @media (max-width:769px) {
                &:not(:last-child){
                }
                min-width: 120px;
                text-align: center;
                margin-right: 70px !important;
            }
            @media (max-width:340px) {
                &:not(:last-child){
                }
                margin-right: 35px !important;
            }
        }
        p{
            color: #000000;
            font-size: pxToEm(15);
            font-family: $font_content;
            margin-top: 20px;
            letter-spacing: 0;
            @media (max-width:768px) {
                font-size: pxToEm(14);
            }
        }
        @media (max-width:992px) {
            &:not(:last-child){
                margin-right: 100px;
            }
        }
        @media (max-width:768px) {
            &:not(:last-child){
                margin-bottom: 45px;
                margin-right: 0;
            }
        }
    }
    .right-textbox{
        color: #000000;
        font-size: pxToEm(15);
        letter-spacing: 0;
        margin-bottom: 65px;
        p{
            line-height: 2;
            &:not(:last-child){
                margin-bottom: 33px;
                @media (max-width:567px) {
                    margin-bottom: 35px;
                }
            }
        }
        .ch{
        padding-bottom: 50px;
        border-bottom: 1px solid #dcdcdc; 
        margin-bottom: 50px;
            @media (max-width:768px) {
                margin-bottom: 35px;
                padding-bottom: 35px;
            }
        }
        @media (max-width:768px) {
                margin-bottom: 35px;
                font-size: pxToEm(14);
                
        }
    }
}

.processPage{
    @media (max-width:992px) {
        main{
        padding-bottom: 0;
        }
    }
    @media (max-width:992px) {
        .title-box{
            padding: 0 15px;
        }    
    }
    
    @media (max-width:800px) {
        .title-box{
            padding: 0 22px;
        }    
    }
    
    h2 {
        font-size: pxToEm(36);
        line-height: 0.32;
        letter-spacing: 1.8px;
        font-family: $font_racontent;
        margin: 210px 0 125px 0  ;
        color: black;
        @media (max-width:768px) {
            margin: 140px 0 60px 0  ;
            font-size: pxToEm(24);
        }
    }
    .process-box{
        display: flex;
        padding: 0 22px;
        @media (max-width:1200px) {
            display: block;
        }
    }
    .top-menu-ul{
        margin-right: 185px;
        opacity: 0;
        position: relative;
        letter-spacing: 0;
        @media  (min-width: 1200px){
            li:not(:last-child){
                position: relative;
                top: -5px;
                margin-bottom: 30px;
                width: 130px;
            }
        }
       
        @media (max-width:1200px) {
            ul li br{
                display: none;
            }    
        }
        
    }
    .right-list{
        width: 846px;
        color: #000000;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        @media  (min-width: 1200px){
            top: -4px;
        }
        @media (max-width:1200px) {
            width: 100%;
            max-width: 772px;
            margin: 0 auto;
        }
        .top-box{
            display: flex;
            justify-content: space-between;
            padding-bottom: 13px;
            border-bottom: 1px solid #000000;
            @media  (max-width: 768px){
                padding-bottom: 9px;
            }
            
        }
        .step-card{
            width: calc((100% - 80px) / 3) ;
            line-height: 2;
            font-size: pxToEm(15);
            letter-spacing: 0;
            height: 310px;
            overflow: hidden;
            margin-bottom: 5px;
            padding-top: 16px;
            opacity: 0;
            position: relative;
            @media(max-width:992px){
                width: calc((100% - 80px) / 2) ;
            }
            @media(max-width:576px){
                width: 100% ;
                padding: 16px 0px 60px 0px;;
                height: auto;
                font-size: pxToEm(14);
            }
        }
        .num{
            font-family: $font_thcontent;
            font-size: pxToEm(57);
            font-weight: 200;
            line-height: 0.2;
            letter-spacing: 2.85px;
            @media (max-width:768px) {
                font-size: pxToEm(50);
            }
        }
        .step{
            font-size: pxToEm(14);
            letter-spacing: 0.7px;
            font-family: $font_racontent;
            @media (max-width:768px) {
                font-size: pxToEm(12);
            }
        }
        .type{
            font-size: pxToEm(15);
            line-height: 2.67;
            color: #7d7d7d;
            margin : 4px 0 21px 0 ;
            @media (max-width:567px) {
                margin : 4px 0 15px 0 ;
                font-size: pxToEm(14);
            }
        }
    }
    @media (max-width:1200px) {
        .left-line{
            border-left:none;
            &::before{
                position: absolute;
                content: '';
                width: 3px;
                height: 27px;
                background:none;
                top: 0;
                left: -4px;
            }
            @media (max-width:768px) {
                &::before{
                    width: 2px;
                    height: 17px;
                    left : -3px;
                }
            }
        }
    }
    .fade1{
        animation: blockfade .4s .4s linear forwards;
    }
    .fade2{
        animation: blockfade .4s .8s linear forwards;
    }
    .fade3{
        animation: blockfade .4s 1.2s linear forwards;
    }
    .fade4{
        animation: blockfade .4s 1.6s linear forwards;
    }
    .fade5{
        animation: blockfade .4s 2s linear forwards;
    }
    .fade6{
        animation: blockfade .4s 2.4s linear forwards;
    }
}
.menu_fade{
    animation: blockfade .4s  linear forwards;
}
.text-fade{
    animation: blockfade .4s .4s linear forwards;
}
@keyframes blockfade {
    0%{
        opacity: 0;
        top: 10px;
    }
    100%{
        top: 0;
        opacity:1;
    }
}
.processPage2{
    .right-list{
        justify-content: start;
    }
    @media (min-width:576px) {
        .mr{
            margin-right: 40px;
        }    
    }
    .tmr{
        @media(min-width:579px) and (max-width:997px) {
                margin-right: 40px; 
        }
    }
    
}