.active{
    color: $hover !important;
   
}

.arr_btn{
    width: 68px;
    height: 15px;
    display: block;
    &:before{
        content: '';
        position: absolute;
        width: 68px;
        height: 1px;
        background: #ffffff;
        bottom: 0;
        left: 0;
        transition: .4s;
        @media (max-width:768px) {
            width: 44px;
            left: auto;
            right: 0;

        }
    }
    &:after{
        content: '';
        position: absolute;
        width: 18px;
        height: 1px;
        background: #ffffff;
        bottom: 6px;
        right: -2px;
        transform: rotate(42deg);
        transition: .4s;
        @media (max-width:768px) {
            width: 15px;
            bottom: 5px;
        }
    }
    @media (min-width:768px) {
        &:hover{
            &:before{
                width: 86px;
            }
            &:after{
                right: -20px;
            }
        }   
    }
}

// 左線共用

.left-line{
    border-left: 1px solid #e41b5b;
    &::before{
        position: absolute;
        content: '';
        width: 3px;
        height: 27px;
        background: #e41b5b;
        top: 0;
        left: -4px;
    }
    &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: -1px;
        width: 5px;
        height: 5px;
        background: #ffffff;
    }
    @media (max-width:768px) {
        &::before{
            width: 1px;
            height: 17px;
            left : -2px;
        }
    }
}
// 手機板 上線條共用
@media(max-width:1200px){
    .mo_topline{
        border-top: 1px solid  #e41b5b;
        display: block;
        width: 772px;
        max-width: 100%;
        margin: 0 auto;

        &::before{
            position: absolute;
            content: '';
            width: 19px;
            height: 3px;
            top: -4px;
            left: 0;
            background: #e41b5b;
        }
        @media (max-width:576px) {
            &::before{
                height: 1px;
                top: -2px;
            }
        }
        @media (max-width:414px) {
            display: block;
            width: 100%;
        }     
    }
}


////  left - menu 共用
@media(max-width:1200px){
 #top-menu-ul{
        @include media-breakpoint-down(md){
            margin:0 auto 35px;
        }
        @include media-breakpoint-up(lg){
            margin:0 auto 50px;
        }
        position: relative;
        z-index: 1;
        padding-top: 10px;
        .item_Menu{
            margin: 0 auto;   
            overflow: hidden;    
            height: 30px;
        }
        
        .item_menu_Box{    
            width:100%;
            overflow:hidden;
            overflow-x: scroll;
            position: relative;
            text-align:left;
            .item_menu_list{
                white-space: nowrap !important;
                padding-bottom: 0px;
                text-align: center;
                font-size:0px;
                display:inline-flex; 
            }

            li{
                height: 30px;
                &:not(:last-child){
                    margin:0 27px 0 0; 
                }
            }
            li a{
                position: relative;
                display: block;
                line-height: 1;
                letter-spacing: 0.6px;
                font-size: pxToEm(14);
                color: #000;
                // border-bottom: 1px solid #dbdbdb;
                @include media-breakpoint-down(md){
                    padding:7px 0 3px;
                }
                @include media-breakpoint-up(lg){                                
                    padding:7px 0;
                }
                @media (max-width:567px) {
                    letter-spacing: 0px;
                }
            
            }
            li.active a{
                border-color:black;
                color:black;
            }
        }
    }
    .flex-direction-nav{
        display:none;
        a{
            display:block;
            width:18px;   
            height: 18px;
            position: absolute;        
            transform: translateY(-50%);
            top:50%;
            z-index: 10;
            &:after{
                position: absolute;
                top: 0;
            }
        }
        a.flex-next{
            background: url(../images/right-arr.png) no-repeat center / 15px;
            right: -18px;
        }

        a.flex-prev{
            background: url(../images/left-arr.png) no-repeat center / 15px;
            left:  -18px;
        }
        }
        .open_flexslider{
        .flex-direction-nav{
            display: block;
        }
    } 
}
li{
    list-style: none !important;
}
.item_menu_list{
    position: relative;
    padding-left: 20px;
    @media (max-width:1200px) {
        padding-left: 0px;
    }
    li a{
        color: #000000;
        transition: .4s;
        text-decoration: none;
        line-height: 1.56;
        font-size: pxToEm(16);
        @media (min-width:992px) {
            &:hover{
                color: $hover;
            }
        }
        
       
    }
    li{
        &:not(:last-child){
            margin-bottom: 35px;
        }
    }
}

.shareh2{
        font-size: pxToEm(36);
        line-height: 0.32;
        letter-spacing: 1.8px;
        font-family: $font_racontent;
        margin: 210px 0 125px   ;
        color: black;
        @media (max-width:768px) {
            margin: 140px 0 50px 0  ;
        }
        @media (max-width:567px) {
            font-size: pxToEm(23);
        }
}


// pagelist

.page{
    text-align: left;
    font-size: pxToEm(15);
    font-family: $font_content;
    margin-bottom: 70px;
    @media(max-width:1200px){
        text-align: center;
    }
    @media(max-width:567px){
        position: relative;
    }
    dd a{
        color: #000000;
        transition: .4s;
        @media (min-width:768px) {
            &:hover{
                color: #e41b5b;
            }    
        }
        
    }
    dd{
        width: 5.3%;
        @media (max-width:567px) {
            width: 15.3%;
        }
        @media (max-width:340px) {
            width: 14.3%;
        }
    }
    .active a{
        color: $hover !important;
        font-weight: 400 !important;
    }
    


}
.page .ltbn{
    margin-right: 15px;
    @media (max-width:1200px) {
        margin-right: 0;
    }
    @media(max-width:567px){
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
    }
    
}
.page .nopage a{
    @media (min-width:992px) {
        &:hover{
            background: #000000 !important;
            &::before{
                background: #000000 !important;
            }
        }    
    }
}
.page dt.ltbn a{
    border-radius: 0;
    background: #000000;
    height: 1px;
    width: 20px;
    opacity: 1;
    position: relative;
    transition: .4s;
    top: 4px;
    @media (min-width:992px) {
        &:hover{
            background:$hover;
            &::before{
                background:$hover;
            }
        }    
    }
    @media (max-width:1200px) {
        margin-right: 0;
    }
    &::before{
        border: none;
        width: 8px;
        height: 1px;
        background: #000000;
        position: absolute;
        top: -3px;
        left: 0;
        transition: .4s;
        transform: rotate(-42deg);
    }
    div{
        height: 20px;
        position: relative;
        top: -10px;
    }
}
.page dt.rtbn a{
    border-radius: 0;
    background: #000000;
    height: 1px;
    width: 20px;
    opacity: 1;
    position: relative;
    transition: .4s;
    margin-left: 0;
    top: 4px;
    @media (min-width:992px) {
        &:hover{
            background:$hover;
            &::before{
                background:$hover;
            }
        }    
    }
    @media(max-width:567px){
        position: absolute;
        top: 62%;
        transform: translateY(-50%);
        right: 0;
    }
    

    &::before{
        border: none;
        width: 8px;
        height: 1px;
        background: #000000;
        position: absolute;
        top: -3px;
        right: 0;
        transition: .4s;
        transform: rotate(42deg);
    }
    div{
        height: 20px;
        position: relative;
        top: -10px;
    }
}

@media (min-width:1200px){
    .stoph2{
        // position: static;
        // .shareh2{
        //     position: absolute !important;
        //     bottom: 0;
        //     margin: 0px 0 350px;
        // }
        display: none;
    }
    .stopmenu{
        .top-menu-ul{
            position: absolute !important;
            bottom: 0;
            opacity: 1 !important;
            top: auto !important;
        }
     
    }
    
}
.showmuh2{
    display: block !important;
    margin-bottom: 140px;
}
