footer{
    background-color: #575757;
    padding: 70px 0 80px 0;
    position: relative;
    z-index: -99;
    .row{
        @media (max-width:768px) {
            margin: 0 0;
        }
    }
    @media  (max-width:768px){
        padding:  50px 0 45px 0 ;
    }
    .fot-box{
        justify-content: space-between;
        padding-bottom: 43px;
        border-bottom: 1px solid #fbf7f2;
        display: flex;
        position: static;
        @media (max-width:768px) {
            display: block;
            padding-left: 0px;
            padding-right: 0px;
            padding-bottom: 33px;
            margin-left: 10px;
            max-width: 100%;
            flex: 0 0 100%;

        }
    }
    .f-logo{
        margin-bottom: 15%;
        margin-top: 3%;
        position: relative;
        @media (max-width:768px) {
            img{
                width: 166px;
                height: 31px;
            }
        }
    }
    .right-box{
        font-size: pxToEm(12);
        line-height: 2;
        letter-spacing: 0.6px;
        text-align: right;
        color: #ffffff;
        @media (min-width:769px) {
            position: relative;
            right: -12px;
        }
        @media (max-width:768px) {
            text-align: left;
            margin:35px 0 30px 0 ;
            font-size: pxToEm(13);
            line-height: 1.77;
        }
        @media (max-width:567px) {
        }
        a{
            color: #fff;
            text-decoration: none;
        }
        @media (min-width:769px) {
            span{
                margin-left: 10px;
            }
        }
    }
    .right-box ul>li br{
        display: none;
        @media(max-width:768px) {
            display: block;
        }
    }
    .left-box{
        @media (min-width:769px) {
            position: relative;
            left: -15px;
        }
    }
    .fbi{
        transition: .4s;
        @media (min-width:768px) {
            &:hover{
                opacity: .7;
            }
        }
        @media (max-width:768px) {
            display: none;
        }
    }
    .mo-fb{
        display: none;
        @media (max-width:768px) {
            display: block;
            .f-fbi-mo{
                width: 25px;
            }
        }
    }
    .top_btn{
        position: absolute;
        bottom: 25%;
        right: 38px;
        opacity: 1;
        color: #ddbc8d;
        writing-mode: vertical-lr;
        font-size: pxToEm(14);
        height: auto;
        @media (min-width:768px) {
            &:hover{
                i{
                    top: -78px;
                    height: 65px;
    
                }
            }
        }
        i{
            content: '';
            position: absolute;
            top: -53px;
            right: 5px;
            width: 2px;
            height: 40px;
            background: #c2a882;
            transition: .4s;
            &::before{
                content: '';
                position: absolute;
                top: 0px;
                right: 5px;
                width: 2px;
                height: 11px;
                background: #c2a882;
                transform: rotate(42deg);
            }
        }
    
    }

}
.top_btn{
    position: fixed;
    bottom: 43px;
    right: 24px;
    opacity: 1;
    color: #ddbc8d;
    writing-mode: vertical-lr;
    font-size: pxToEm(14);
    height: 85px;
    text-align: end;
    z-index: 0;
    opacity: 0;
    @media (min-width:768px) {
        bottom: 78px;
        right: 38px;
        &:hover{
            i{
                top: -10px;
                height: 50px;

            }
        }
    }
    i{
        content: '';
        position: absolute;
        top: 0px;
        right: 5px;
        width: 1px;
        height: 40px;
        background: #c2a882;
        transition: .4s;
        &::before{
            content: '';
            position: absolute;
            top: -1px;
            right: 4px;
            width: 1px;
            height: 11px;
            background: #c2a882;
            transform: rotate(42deg);
        }
    }


}
.f-mo-mr{
    @media (max-width:768px) {
        padding-right: 60px;
    }
}
.pc-f-logo{
    @media (max-width:768px) {
        display: none;
    }
}
.mo-f-logo{
    display: none;
    @media (max-width:768px) {
        display: block;
    }
}