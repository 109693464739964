@import url(https://fonts.googleapis.com/css?family=Noto+Sans+TC:300,400,500,700|Raleway:300,400,700&display=swap);@import url(https://fonts.googleapis.com/css?family=Mukta:200,400&display=swap);@import url(https://fonts.googleapis.com/earlyaccess/cwtexfangsong.css?20190222);@charset "UTF-8";
/******************************************/
/*		bootstrap-okadmin
/******************************************/
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #868e96; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
          box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

/* > reset */
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
      touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
          flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 8.33333%;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.66667%;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.33333%;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 41.66667%;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 58.33333%;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 66.66667%;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 75%;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 83.33333%;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 91.66667%;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
      -ms-flex-order: -1;
          order: -1; }

.order-1 {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
      -ms-flex-order: 2;
          order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
      -ms-flex-order: 3;
          order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
  -webkit-order: 4;
      -ms-flex-order: 4;
          order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
  -webkit-order: 5;
      -ms-flex-order: 5;
          order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
  -webkit-order: 6;
      -ms-flex-order: 6;
          order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
  -webkit-order: 7;
      -ms-flex-order: 7;
          order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
  -webkit-order: 8;
      -ms-flex-order: 8;
          order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
  -webkit-order: 9;
      -ms-flex-order: 9;
          order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
  -webkit-order: 10;
      -ms-flex-order: 10;
          order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
  -webkit-order: 11;
      -ms-flex-order: 11;
          order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
  -webkit-order: 12;
      -ms-flex-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
        -ms-flex-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
        -ms-flex-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
        -ms-flex-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
        -ms-flex-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
        -ms-flex-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
        -ms-flex-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
        -ms-flex-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
        -ms-flex-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
        -ms-flex-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
        -ms-flex-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
        -ms-flex-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
        -ms-flex-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
        -ms-flex-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
        -ms-flex-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
        -ms-flex-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
        -ms-flex-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
        -ms-flex-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
        -ms-flex-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
        -ms-flex-order: -1;
            order: -1; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
        -ms-flex-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
        -ms-flex-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
        -ms-flex-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
        -ms-flex-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
        -ms-flex-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
        -ms-flex-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
        -ms-flex-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: column !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -webkit-flex-direction: row-reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -webkit-flex-direction: column-reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
      -ms-flex-wrap: wrap !important;
          flex-wrap: wrap !important; }

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
      -ms-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
      -ms-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
  -webkit-justify-content: flex-start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
  -webkit-justify-content: flex-end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
  -webkit-justify-content: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important; }

.justify-content-around {
  -webkit-justify-content: space-around !important;
      -ms-flex-pack: distribute !important;
          justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
  -webkit-align-items: flex-start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
  -webkit-align-items: flex-end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
      -ms-flex-align: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -webkit-align-items: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -webkit-align-items: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  -webkit-align-content: flex-start !important;
      -ms-flex-line-pack: start !important;
          align-content: flex-start !important; }

.align-content-end {
  -webkit-align-content: flex-end !important;
      -ms-flex-line-pack: end !important;
          align-content: flex-end !important; }

.align-content-center {
  -webkit-align-content: center !important;
      -ms-flex-line-pack: center !important;
          align-content: center !important; }

.align-content-between {
  -webkit-align-content: space-between !important;
      -ms-flex-line-pack: justify !important;
          align-content: space-between !important; }

.align-content-around {
  -webkit-align-content: space-around !important;
      -ms-flex-line-pack: distribute !important;
          align-content: space-around !important; }

.align-content-stretch {
  -webkit-align-content: stretch !important;
      -ms-flex-line-pack: stretch !important;
          align-content: stretch !important; }

.align-self-auto {
  -webkit-align-self: auto !important;
      -ms-flex-item-align: auto !important;
          align-self: auto !important; }

.align-self-start {
  -webkit-align-self: flex-start !important;
      -ms-flex-item-align: start !important;
          align-self: flex-start !important; }

.align-self-end {
  -webkit-align-self: flex-end !important;
      -ms-flex-item-align: end !important;
          align-self: flex-end !important; }

.align-self-center {
  -webkit-align-self: center !important;
      -ms-flex-item-align: center !important;
          align-self: center !important; }

.align-self-baseline {
  -webkit-align-self: baseline !important;
      -ms-flex-item-align: baseline !important;
          align-self: baseline !important; }

.align-self-stretch {
  -webkit-align-self: stretch !important;
      -ms-flex-item-align: stretch !important;
          align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important; }
  .align-content-sm-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important; }
  .align-content-sm-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important; }
  .align-content-sm-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important; }
  .align-content-sm-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important; }
  .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important; }
  .align-self-sm-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important; }
  .align-self-sm-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important; }
  .align-self-sm-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important; }
  .align-self-sm-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important; }
  .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important; }
  .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important; }
  .align-content-md-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important; }
  .align-content-md-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important; }
  .align-content-md-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important; }
  .align-content-md-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important; }
  .align-content-md-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important; }
  .align-self-md-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important; }
  .align-self-md-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important; }
  .align-self-md-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important; }
  .align-self-md-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important; }
  .align-self-md-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important; }
  .align-self-md-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important; }
  .align-content-lg-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important; }
  .align-content-lg-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important; }
  .align-content-lg-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important; }
  .align-content-lg-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important; }
  .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important; }
  .align-self-lg-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important; }
  .align-self-lg-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important; }
  .align-self-lg-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important; }
  .align-self-lg-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important; }
  .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important; }
  .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
            justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
            align-content: flex-start !important; }
  .align-content-xl-end {
    -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
            align-content: flex-end !important; }
  .align-content-xl-center {
    -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
            align-content: center !important; }
  .align-content-xl-between {
    -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
            align-content: space-between !important; }
  .align-content-xl-around {
    -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
            align-content: space-around !important; }
  .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
            align-content: stretch !important; }
  .align-self-xl-auto {
    -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
            align-self: auto !important; }
  .align-self-xl-start {
    -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
            align-self: flex-start !important; }
  .align-self-xl-end {
    -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
            align-self: flex-end !important; }
  .align-self-xl-center {
    -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
            align-self: center !important; }
  .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
            align-self: baseline !important; }
  .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
            align-self: stretch !important; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

/******************************************/
/*		slick
/******************************************/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.pc_Banner .slick-slide {
  height: 371px; }

.mo_Banner .slick-slide {
  height: 200px; }

/* Slider */
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url(../assets/slick.eot?ced611da);
  src: url(../assets/slick.eot?ced611da?#iefix) format("embedded-opentype"), url(../assets/slick.woff?b7c9e1e4) format("woff"), url(../assets/slick.ttf?d41f55a7) format("truetype"), url(../assets/slick.svg?f97e3bbf#slick) format("svg"); }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 99; }

/*.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}*/
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1; }

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px;
  /*    background: url(../images/are.png) 8px 0 no-repeat;*/ }

.slick-next {
  right: -25px;
  /*    background: url(../images/are.png) -10px 0 no-repeat;*/ }

/* Dots */
.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: -39px 0 0 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 11px;
  height: 11px !important;
  margin: 0 10px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: transparent !important; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  display: table;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 7px;
  height: 7px;
  border-radius: 99em;
  background: #fff;
  content: '';
  text-align: center;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before {
  background: #000; }

.topContent .slick-next, .topContent .slick-prev {
  display: none  !important; }

.topContent .slick-dots li {
  width: 20px; }

.topContent .slick-dots {
  position: relative;
  margin: 29px 0 0 0; }

.tagContent .slick-dots {
  margin: 0px 0 0 0; }

.tagContent .slick-dots li button:before {
  border: none; }

.exposeCase .topicContent .slick-dots {
  margin: 0px 0 0 0; }

.exposeCase .topicContent .slick-dots li {
  width: 20px; }

.exposeCase .topicContent .slick-dots li button:before {
  border: none;
  background: #959595; }

.exposeCase .topicContent .slick-dots li.slick-active button:before {
  border: none;
  background: #6bad3d; }

.learningBox .exposeCase .topicContent li {
  margin: 0px 4px 20px 0; }

.exposeCase .slick-prev {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/le_ribtn.jpg) top left no-repeat; */
  left: -46px; }

.exposeCase .slick-next {
  width: 46px;
  height: 108px;
  display: block;
  /* background: url(../images/ex_ribtn.jpg) top left no-repeat; */
  right: -29px; }

.navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -11px; }

.eventBox .navigation .tagContent .slick-dots {
  margin: -13px 0 0 0;
  width: auto;
  text-align: right;
  position: relative;
  bottom: -26px; }

.navigation .slick-dots li button:before {
  background: #959595; }

.navigation .slick-dots li.slick-active button:before {
  background: #6bad3d; }

.recentBox .slick-dots {
  display: none !important; }

.recentBox .slick-list {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.banner .slick-prev, .recentBox .slick-prev {
  left: 0px;
  width: 40px;
  height: 93px; }

.banner .slick-next, .recentBox .slick-next {
  right: 0px;
  width: 40px;
  height: 93px; }

/******************************************/
/*		mCustomScrollbar
/******************************************/
/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  /* direct pointer events to js */ }

.mCustomScrollbar.mCS_no_scrollbar,
.mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr; }

.mCSB_container {
  /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto; }

/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
  margin-right: 30px; }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0; }

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px; }

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger {
  /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 30px;
  /* minimum dragger height */
  z-index: 1; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  /* the dragger element */
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  border-radius: 16px;
  text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  /* minimum dragger width */
  height: 100%;
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px; }

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0; }

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/
/* 
	----------------------------------------
	6.1 THEMES 
	----------------------------------------
	*/
/* default theme ("light") */
.mCSB_scrollTools {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)"; }

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /* 
		sprites locations 
		light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
		dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
		*/ }

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /* 
		sprites locations
		light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
		dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
		*/ }

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /* 
		sprites locations 
		light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
		dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
		*/ }

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /* 
		sprites locations 
		light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
		dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
		*/ }

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */ }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII="); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }

.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  border-radius: 16px; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2);
          box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1);
          box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  border-radius: 7px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  border-radius: 5px; }

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  -webkit-box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
          box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
          box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
          box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  -webkit-box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2);
          box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
          box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

/* ---------------------------------------- */
/******************************************/
/*		fullpage
/******************************************/
/******************************************/
/*		base 歸零 變數
/******************************************/
/* http://meyerweb.com/eric/tools/css/reset/ */
/* v1.0 | 20080212 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tfoot,
thead,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  /* vertical-align: baseline; */ }

tr,
th,
td {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font: inherit; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

/*ol, ul {
	list-style: none;
}*/
blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

/*背景沒連結時用*/
.myMOUSE {
  cursor: default; }

.error {
  font-size: 12px;
  color: #fc7314; }

/*把變數帶入mixin中，此mixin只就單純把media queries寫到mixin中，
並且使用上面設立的變數來調動media queries的width*/
main[role="main"], section, article {
  zoom: 1; }
  main[role="main"]:before, section:before, article:before, main[role="main"]:after, section:after, article:after {
    content: "";
    display: table;
    height: 0;
    overflow: hidden; }
  main[role="main"]:after, section:after, article:after {
    clear: both; }

/******************************************/
/*		layout 共用
/******************************************/
/*----------------------通用設定------------------------*/
p::-moz-selection,
h1::-moz-selection,
h2::-moz-selection,
h3::-moz-selection,
h4::-moz-selection,
b::-moz-selection,
strong::-moz-selection,
span::-moz-selection,
li::-moz-selection,
div::-moz-selection,
a::-moz-selection,
img::-moz-selection,
tr::-moz-selection,
td::-moz-selection,
th::-moz-selection {
  color: #ffffff;
  background: #e41b5b; }
p::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
b::selection,
strong::selection,
span::selection,
li::selection,
div::selection,
a::selection,
img::selection,
tr::selection,
td::selection,
th::selection {
  color: #ffffff;
  background: #e41b5b; }

body {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  line-height: 1em;
  overflow: auto;
  font-size: 16px;
  color: #000000;
  word-break: break-word;
  letter-spacing: 0.1em;
  font-family: "Noto Sans TC", sans-serif;
  z-index: 0;
  background: #ffffff;
  -webkit-text-size-adjust: 100%;
  font-weight: 300; }

a {
  text-decoration: none;
  outline: 0; }

a,
a:hover {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s; }

img {
  vertical-align: middle;
  max-width: 100%; }

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important; }

a[href^="tel"] {
  color: inherit;
  /* Inherit text color of parent element. */
  text-decoration: none;
  /* Remove underline. */
  /* Additional css `propery: value;` pairs here… */ }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.jqimgFill {
  position: relative;
  overflow: hidden;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: inline-block;
  -webkit-transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
  -o-transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out, -webkit-transform .4s ease-in-out; }

.jqimgFill img,
.jqimgFill-tc img {
  left: 0;
  top: 0;
  margin: 0;
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  max-height: none;
  max-width: none;
  -webkit-transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
  -o-transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out, -webkit-transform .4s ease-in-out; }

input,
select,
textarea {
  width: 100%;
  height: 56px;
  outline: none;
  padding: 0 10px;
  color: #000;
  border: 1px solid #ffffff;
  font-size: 1rem;
  border-radius: 5px; }
  @media screen and (max-width: 640px) {
    input,
    select,
    textarea {
      font-size: 0.9375rem; } }
  input:focus,
  select:focus,
  textarea:focus {
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    border: 1px solid #eee; }

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background: #000 !important; }

textarea {
  height: 218px;
  padding: 10px; }

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #dcdcdc; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #dcdcdc; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #dcdcdc; }

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #dcdcdc; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

input[type='date'],
input[type='time'] {
  -webkit-appearance: none; }

select {
  font-family: "Noto Sans TC", sans-serif;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-size: 3.4%;
  padding-right: 14px;
  height: 40px;
  width: 100%; }

select::-ms-expand {
  display: none; }

.selectBox {
  background: #FFF; }

body :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }
  @media screen and (max-width: 960px) {
    body :target:before {
      height: 100px;
      margin: -100px 0 0; } }

body .slide-down :target:before {
  display: block;
  content: "";
  height: 120px;
  margin: -120px 0 0; }

.slick-slide {
  outline: 0; }

/*----------------------大區塊------------------------*/
#Wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 320px !important;
  overflow: hidden; }

main[role="main"] {
  display: block;
  width: 100%;
  position: relative;
  padding-bottom: 55px;
  z-index: 1; }

section {
  position: relative; }

article {
  width: 1200px;
  max-width: 85%;
  margin: 0 auto;
  position: relative;
  z-index: 1; }

/*----------------------每個選單Highlight------------------------*/
/*----------------------內頁單元標題------------------------*/
/*----------------------tag_list------------------------*/
.tag_list a {
  color: #ffffff;
  text-decoration: none;
  border-radius: 10px;
  background: #434343;
  padding: 3px 7px;
  font-size: 0.9375rem; }
  .tag_list a:not(:last-child) {
    margin-right: 15px; }
  .tag_list a:hover {
    background: #2f3a88; }

/*----------------------編輯器------------------------*/
.editor_Content {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 0; }
  .editor_Content header {
    width: 100%;
    padding-bottom: 31px;
    margin-bottom: 29px;
    border-bottom: solid 1px #7d7d7d;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    .editor_Content header h2 {
      width: 80%;
      font-size: 1.25rem;
      line-height: 1.4;
      color: #ffffff;
      padding-right: 2%; }
    .editor_Content header .tag_list {
      text-align: right;
      width: calc(100% - 73%); }

.editor_Box {
  margin: auto;
  line-height: 1.5;
  font-size: 0.9375rem;
  color: #ffffff; }
  .editor_Box ul,
  .editor_Box ol {
    padding-left: 40px; }
  .editor_Box img {
    max-width: 100%;
    height: auto !important; }
  .editor_Box iframe {
    max-width: 100%; }
    @media screen and (max-width: 767px) {
      .editor_Box iframe {
        height: auto !important; } }
  .editor_Box h1,
  .editor_Box h2,
  .editor_Box h3,
  .editor_Box h4,
  .editor_Box h5,
  .editor_Box h6 {
    margin: inherit; }

.mo_use {
  display: none; }

/*----------------------back------------------------*/
.back_btn,
.more_btn {
  width: 100%;
  margin-top: 70px;
  font-size: 1.125rem; }
  .back_btn a,
  .more_btn a {
    margin: auto;
    display: inline-block;
    color: #ffffff;
    text-decoration: none;
    padding: 19.5px 49px;
    background-color: #4759d9;
    border-radius: 5px; }
    .back_btn a:hover,
    .more_btn a:hover {
      background: #4759d9;
      opacity: .5; }

/*----------------------上下則------------------------*/
.seesaw {
  position: relative;
  width: 100%;
  font-size: 1rem;
  margin-top: 61px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  .seesaw a {
    color: #fd7e14;
    text-decoration: none; }
  .seesaw .left_button:before,
  .seesaw .right_button:before,
  .seesaw .back_button {
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s; }
  .seesaw .left_button,
  .seesaw .right_button {
    position: absolute; }
    .seesaw .left_button:before,
    .seesaw .right_button:before {
      content: '';
      display: block;
      width: 70px;
      height: 2px;
      background: #fd7e14;
      margin-bottom: 2px; }
    .seesaw .left_button:hover:before,
    .seesaw .right_button:hover:before {
      width: 90px; }
  .seesaw .left_button {
    right: 0; }
  .seesaw .right_button {
    left: 0;
    text-align: right; }
  .seesaw .back_button {
    font-size: 1rem;
    width: 230px;
    height: auto;
    padding: 11px 0;
    border: 1px solid #fd7e14;
    text-align: center; }
    .seesaw .back_button:hover {
      background: #fd7e14; }
      .seesaw .back_button:hover a {
        color: #ffffff; }

/*----------------------GO TOP------------------------*/
.top_btn {
  cursor: pointer;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  opacity: 0;
  position: fixed;
  bottom: 3%;
  right: 43px;
  z-index: 8;
  font-size: 0.75rem;
  z-index: 9;
  width: 18px;
  height: 76px; }
  .top_btn a {
    display: block;
    color: #ffffff;
    position: absolute;
    bottom: 0;
    left: 16px; }
  .top_btn:hover a {
    color: #4759d9; }
  .top_btn.active {
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
    opacity: 1; }
  .top_btn.fix {
    position: absolute;
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
    bottom: 112.2%; }
    @media screen and (max-width: 768px) {
      .top_btn.fix {
        bottom: 55.2%; } }

/*----------------------頁數------------------------*/
.page dt.ltbn a:before, .page dt.rtbn a:before {
  display: block;
  margin: 0 auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%); }

.page {
  font-size: 1.125rem;
  clear: both;
  width: 100%;
  text-align: center;
  margin: 0 auto; }
  .page dt,
  .page dd {
    vertical-align: middle;
    display: inline-block; }
    .page dt a,
    .page dd a {
      display: block;
      height: 32px;
      line-height: 30px;
      text-decoration: none;
      color: #ffffff; }
      .page dt a:hover,
      .page dd a:hover {
        color: #2f3a88; }
  .page dd {
    width: 2.3%; }
  .page dd.active a {
    border-radius: 99em;
    background: none;
    padding: 0;
    color: #4759d9;
    font-weight: bold; }
  .page dt.rtbn a,
  .page dt.ltbn a {
    width: 32px;
    height: 32px;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    border-radius: 99em;
    background: #2b2c2e; }
  .page .nopage {
    opacity: 0.2; }
  .page dt.ltbn a {
    margin-right: 17px; }
    .page dt.ltbn a:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 7px 7px 0;
      border-color: transparent #ffffff transparent transparent; }
    .page dt.ltbn a:hover {
      background: #2f3a88; }
  .page dt.rtbn a {
    margin-left: 17px; }
    .page dt.rtbn a:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 0 7px 7px;
      border-color: transparent transparent transparent #ffffff; }
    .page dt.rtbn a:hover {
      background: #2f3a88; }

@media screen and (max-width: 768px) {
  .mo_use {
    display: block; }
  .pc_use {
    display: none; }
  .top_btn {
    right: 20px; }
  right: 20px; }

@media (max-width: 575.99px) {
  .page {
    font-size: 1.0625rem; }
    .page dd {
      width: 11%; }
    .page dt.ltbn a {
      margin-right: 0.875rem; }
      .page dt.ltbn a:before {
        border-width: 5px 5px 5px 0; }
    .page dt.rtbn a {
      margin-left: 0.875rem; }
      .page dt.rtbn a:before {
        border-width: 5px 0 5px 5px; }
    .page dt.ltbn a,
    .page dt.rtbn a {
      width: 28px;
      height: 28px; }
  .back_btn,
  .more_btn {
    font-size: 1.0625rem; }
    .back_btn a,
    .more_btn a {
      padding: 12.5px 25px;
      border-radius: 4px; } }

/*----------------------header------------------------*/
.header_show {
  -webkit-transition: .4S;
  -o-transition: .4S;
  transition: .4S;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(19, 19, 20, 0);
  z-index: 10; }
  .header_show li {
    list-style: none; }
  .header_show.active {
    background: #131314; }

.header_box {
  position: relative;
  width: 100%;
  height: 79px;
  padding: 0 2vw;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 0.875rem;
  background: white;
  top: -106px;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s; }

.m-nav {
  -webkit-animation: header-inshow .6s linear forwards;
          animation: header-inshow .6s linear forwards; }

@-webkit-keyframes header-inshow {
  0% {
    top: -106px; }
  100% {
    top: 0; } }

@keyframes header-inshow {
  0% {
    top: -106px; }
  100% {
    top: 0; } }

.logo {
  width: 220px;
  height: 39px;
  background: url(../svg/gogod-logo.svg?f4337e59) top left no-repeat;
  background-size: contain;
  text-indent: -10000px; }
  .logo a {
    width: 100%;
    height: 100%;
    display: block; }

/*----------------------nav------------------------*/
.nav_box {
  position: relative; }
  .nav_box ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-weight: 400; }
    .nav_box ul li {
      position: relative;
      text-align: center; }
      @media (max-width: 768px) {
        .nav_box ul li {
          color: #fff !important; } }
      @media (min-width: 992px) {
        .nav_box ul li:hover a {
          color: #e41b5b; } }
    .nav_box ul li:not(:last-child) {
      margin-right: 50px; }
  .nav_box a {
    width: 100%;
    display: block;
    color: #000000;
    text-decoration: none;
    font-family: "Raleway", sans-serif;
    font-size: 1rem; }
    .nav_box a:after {
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s;
      position: relative;
      content: '';
      width: 0;
      height: 2px;
      background: #4759d9;
      bottom: -26px;
      left: 8px;
      opacity: 0; }
    .nav_box a:hover:after {
      width: 77%;
      opacity: 1; }
    .nav_box a:hover .en {
      opacity: 0; }
    .nav_box a:hover .tw {
      opacity: 1; }

/*----------------------nav icon------------------------*/
.menu-wrapper {
  display: none; }

.menu-wrapper {
  display: none;
  position: absolute;
  top: 25px;
  right: 25px;
  bottom: 0;
  margin: auto;
  width: 31px;
  height: 37px;
  cursor: pointer;
  z-index: 50; }

.hamburger-menu:after,
.hamburger-menu:before {
  width: 27px;
  height: 2px;
  border-radius: 99em; }

.hamburger-menu {
  position: relative;
  -webkit-transform: translateY(10px);
      -ms-transform: translateY(10px);
          transform: translateY(10px);
  background: #bfbfbf; }

.hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  background: #bfbfbf;
  border-radius: 99px; }

.hamburger-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #bfbfbf;
  -webkit-transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 99px; }

.menu-icon {
  display: none;
  top: 45%;
  right: -0.7em;
  z-index: 100;
  width: 45px;
  height: 39px;
  margin-top: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  position: absolute; }
  @media (max-width: 768px) {
    .menu-icon {
      display: block; } }

.menu-icon-block {
  display: none;
  position: relative;
  height: 100%;
  margin: auto; }
  .menu-icon-block i {
    position: absolute;
    display: block;
    margin: auto;
    width: 72%;
    height: 2px;
    background-color: #ffffff;
    -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 99em; }
    .menu-icon-block i.i1 {
      top: 0%; }
    .menu-icon-block i.i2 {
      top: 25%; }
    .menu-icon-block i.i3 {
      top: 51%; }

@media (max-width: 991.99px) {
  .logo {
    width: 170px;
    height: 34px; }
  .header_box {
    width: 100%;
    height: 70px;
    font-size: 1.0625rem;
    padding: 0 25px; }
  .nav_box nav {
    width: 90%;
    margin: auto; }
  .nav_box {
    display: none;
    position: fixed;
    top: 69px;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 11;
    background: #ca275f;
    padding-top: 20px; }
    .nav_box ul {
      display: block; }
      .nav_box ul li {
        width: 100%;
        margin-bottom: 0; }
        .nav_box ul li a {
          padding: 30px 15px;
          text-align: center;
          text-decoration: none;
          color: #fff; }
        .nav_box ul li .active {
          color: #ffffff !important; }
    .nav_box ul > li:not(:last-child) {
      margin-right: 0; }
  .menu-wrapper {
    display: block; } }

.ch-nav {
  background: rgba(0, 0, 0, 0);
  top: 0; }
  .ch-nav .logo {
    background: url(../svg/index-logo.svg?19a8a173) no-repeat;
    z-index: 50; }
  .ch-nav .nav_box {
    margin-top: 50px;
    position: absolute;
    top: 0px;
    right: -5%;
    -webkit-animation: chnav .4s linear forwards;
            animation: chnav .4s linear forwards; }
    @media (max-width: 992px) {
      .ch-nav .nav_box {
        margin-top: 0;
        padding-top: 80px; } }
  .ch-nav .nav_box ul {
    display: block; }
  .ch-nav .nav_box ul li {
    text-align: end; }
  .ch-nav .nav_box ul li:not(:last-child) {
    margin-right: 0; }
  .ch-nav .nav_box li {
    margin-bottom: 50px;
    position: relative; }
    @media (max-width: 992px) {
      .ch-nav .nav_box li {
        margin-bottom: 0px; } }
    .ch-nav .nav_box li::before {
      position: absolute;
      content: '';
      top: 50%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      left: 110%;
      width: 0px;
      height: 1px;
      background: white;
      -webkit-transition: all .4s;
      -o-transition: all .4s;
      transition: all .4s; }
    @media (min-width: 769px) {
      .ch-nav .nav_box li:hover {
        color: #fff; }
        .ch-nav .nav_box li:hover::before {
          width: 30px; } }
  .ch-nav .nav_box a {
    color: #fff; }
    @media (min-width: 769px) {
      .ch-nav .nav_box a:hover {
        color: #fff; } }
  .ch-nav .hamburger-menu:after {
    background: #fff; }
  .ch-nav .hamburger-menu:before {
    background: #fff; }

@-webkit-keyframes chnav {
  0% {
    right: -5%; }
  100% {
    right: 2%; } }

@keyframes chnav {
  0% {
    right: -5%; }
  100% {
    right: 2%; } }

.open {
  background: #ca275f; }
  .open .hamburger-menu:after {
    background: #fff; }
  .open .hamburger-menu:before {
    background: #fff; }
  .open .logo {
    background: url(../svg/index-logo.svg?19a8a173) no-repeat; }

.animate::before {
  -webkit-animation: humanibe 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
          animation: humanibe 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards; }

.animate::after {
  -webkit-animation: humaniaf 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
          animation: humaniaf 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards; }

@-webkit-keyframes humanibe {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    bottom: 10px; }
  100% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
    bottom: 3px; } }

@keyframes humanibe {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    bottom: 10px; }
  100% {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
    bottom: 3px; } }

@-webkit-keyframes humaniaf {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: 0; }
  50% {
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
    top: 0; }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: -5px; } }

@keyframes humaniaf {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: 0; }
  50% {
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
    top: 0; }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: -5px; } }

.colsanimate::before {
  -webkit-animation: colshumbf 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
          animation: colshumbf 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards; }

.colsanimate::after {
  -webkit-animation: colshumaf 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards;
          animation: colshumaf 0.4s cubic-bezier(0.5, 0, 0.3, 0.9) forwards; }

@-webkit-keyframes colshumbf {
  0% {
    bottom: 3px;
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg); }
  100% {
    bottom: 10px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes colshumbf {
  0% {
    bottom: 3px;
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg); }
  100% {
    bottom: 10px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@-webkit-keyframes colshumaf {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: -5px; }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: 0; } }

@keyframes colshumaf {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: -5px; }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: 0; } }

footer {
  background-color: #575757;
  padding: 70px 0 80px 0;
  position: relative;
  z-index: -99; }
  @media (max-width: 768px) {
    footer .row {
      margin: 0 0; } }
  @media (max-width: 768px) {
    footer {
      padding: 50px 0 45px 0; } }
  footer .fot-box {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-bottom: 43px;
    border-bottom: 1px solid #fbf7f2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: static; }
    @media (max-width: 768px) {
      footer .fot-box {
        display: block;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 33px;
        margin-left: 10px;
        max-width: 100%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
            -ms-flex: 0 0 100%;
                flex: 0 0 100%; } }
  footer .f-logo {
    margin-bottom: 15%;
    margin-top: 3%;
    position: relative; }
    @media (max-width: 768px) {
      footer .f-logo img {
        width: 166px;
        height: 31px; } }
  footer .right-box {
    font-size: 0.75rem;
    line-height: 2;
    letter-spacing: 0.6px;
    text-align: right;
    color: #ffffff; }
    @media (min-width: 769px) {
      footer .right-box {
        position: relative;
        right: -12px; } }
    @media (max-width: 768px) {
      footer .right-box {
        text-align: left;
        margin: 35px 0 30px 0;
        font-size: 0.8125rem;
        line-height: 1.77; } }
    footer .right-box a {
      color: #fff;
      text-decoration: none; }
    @media (min-width: 769px) {
      footer .right-box span {
        margin-left: 10px; } }
  footer .right-box ul > li br {
    display: none; }
    @media (max-width: 768px) {
      footer .right-box ul > li br {
        display: block; } }
  @media (min-width: 769px) {
    footer .left-box {
      position: relative;
      left: -15px; } }
  footer .fbi {
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s; }
    @media (min-width: 768px) {
      footer .fbi:hover {
        opacity: .7; } }
    @media (max-width: 768px) {
      footer .fbi {
        display: none; } }
  footer .mo-fb {
    display: none; }
    @media (max-width: 768px) {
      footer .mo-fb {
        display: block; }
        footer .mo-fb .f-fbi-mo {
          width: 25px; } }
  footer .top_btn {
    position: absolute;
    bottom: 25%;
    right: 38px;
    opacity: 1;
    color: #ddbc8d;
    -webkit-writing-mode: vertical-lr;
        -ms-writing-mode: tb-lr;
            writing-mode: vertical-lr;
    font-size: 0.875rem;
    height: auto; }
    @media (min-width: 768px) {
      footer .top_btn:hover i {
        top: -78px;
        height: 65px; } }
    footer .top_btn i {
      content: '';
      position: absolute;
      top: -53px;
      right: 5px;
      width: 2px;
      height: 40px;
      background: #c2a882;
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s; }
      footer .top_btn i::before {
        content: '';
        position: absolute;
        top: 0px;
        right: 5px;
        width: 2px;
        height: 11px;
        background: #c2a882;
        -webkit-transform: rotate(42deg);
            -ms-transform: rotate(42deg);
                transform: rotate(42deg); }

.top_btn {
  position: fixed;
  bottom: 43px;
  right: 24px;
  opacity: 1;
  color: #ddbc8d;
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr;
  font-size: 0.875rem;
  height: 85px;
  text-align: end;
  z-index: 0;
  opacity: 0; }
  @media (min-width: 768px) {
    .top_btn {
      bottom: 78px;
      right: 38px; }
      .top_btn:hover i {
        top: -10px;
        height: 50px; } }
  .top_btn i {
    content: '';
    position: absolute;
    top: 0px;
    right: 5px;
    width: 1px;
    height: 40px;
    background: #c2a882;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s; }
    .top_btn i::before {
      content: '';
      position: absolute;
      top: -1px;
      right: 4px;
      width: 1px;
      height: 11px;
      background: #c2a882;
      -webkit-transform: rotate(42deg);
          -ms-transform: rotate(42deg);
              transform: rotate(42deg); }

@media (max-width: 768px) {
  .f-mo-mr {
    padding-right: 60px; } }

@media (max-width: 768px) {
  .pc-f-logo {
    display: none; } }

.mo-f-logo {
  display: none; }
  @media (max-width: 768px) {
    .mo-f-logo {
      display: block; } }

/******************************************/
/*		pages
/******************************************/
.indexPage .index-banner {
  position: relative; }

.indexPage .pc-banner {
  height: 100vh;
  -webkit-transition: background 2s;
  -o-transition: background 2s;
  transition: background 2s; }
  @media (max-width: 992px) {
    .indexPage .pc-banner {
      display: none; } }
  .indexPage .pc-banner .i1 .box1 {
    top: 28.5%;
    left: 43.5%; }
    .indexPage .pc-banner .i1 .box1 .arr_btn {
      right: 0;
      bottom: -71px; }
  .indexPage .pc-banner .i2 {
    display: none; }
    .indexPage .pc-banner .i2 .box1 {
      top: 28.5%;
      left: 54.5%; }
      .indexPage .pc-banner .i2 .box1 .arr_btn {
        right: 8px;
        bottom: -72px; }
  .indexPage .pc-banner .i3 {
    display: none; }
    .indexPage .pc-banner .i3 .box1 {
      top: 29.5%;
      left: 26%; }
      .indexPage .pc-banner .i3 .box1 .arr_btn {
        right: -1px;
        bottom: -67px; }
  .indexPage .pc-banner .i4 {
    display: none; }
    .indexPage .pc-banner .i4 .box1 {
      top: 29%;
      left: 31%; }
      .indexPage .pc-banner .i4 .box1 .arr_btn {
        right: 0px;
        bottom: -71px; }
  .indexPage .pc-banner .banner-box {
    position: absolute; }
    .indexPage .pc-banner .banner-box h2 {
      font-size: 3.875rem;
      line-height: 1.35;
      letter-spacing: 3.1px;
      font-family: "Raleway", sans-serif;
      color: #fff; }
      @media (max-width: 768px) {
        .indexPage .pc-banner .banner-box h2 {
          font-size: 1.75rem; } }
    .indexPage .pc-banner .banner-box a {
      position: absolute; }
    .indexPage .pc-banner .banner-box span {
      margin-left: 18px; }

.indexPage .pc-banner .it {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0; }
  .indexPage .pc-banner .it .it-bx {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 885px;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s; }
    .indexPage .pc-banner .it .it-bx img {
      opacity: 0;
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s; }
    .indexPage .pc-banner .it .it-bx .box1 {
      opacity: 0;
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s; }

.indexPage .show-me:before {
  height: 3px !important;
  background: #fff !important; }

.indexPage .show-box {
  position: absolute;
  bottom: 64px;
  left: 50%;
  z-index: 10;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  @media (max-width: 992px) {
    .indexPage .show-box {
      bottom: 20px; } }
  .indexPage .show-box .go-it {
    width: 38px;
    height: 15px;
    margin: 0 3px;
    cursor: pointer;
    position: relative; }
    .indexPage .show-box .go-it:before {
      width: 38px;
      height: 1px;
      background: #fff;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0; }

.indexPage .chbanner .it-bx img {
  -webkit-animation: imgfain .5s .7s linear forwards;
          animation: imgfain .5s .7s linear forwards; }

.indexPage .chbanner .it-bx .box1 {
  -webkit-animation: imgfain .5s 1.2s linear forwards;
          animation: imgfain .5s 1.2s linear forwards; }

@-webkit-keyframes imgfain {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes imgfain {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.indexPage .mo-banner {
  height: 100vh;
  display: none;
  -webkit-transition: 2s;
  -o-transition: 2s;
  transition: 2s; }
  @media (max-width: 992px) {
    .indexPage .mo-banner {
      display: block;
      height: 48vh; } }
  @media (max-width: 768px) {
    .indexPage .mo-banner {
      height: 48vh; } }
  .indexPage .mo-banner .banner-box {
    position: absolute; }
    .indexPage .mo-banner .banner-box h2 {
      font-size: 1.6875rem;
      line-height: 1.35;
      letter-spacing: 3.1px;
      font-family: "Raleway", sans-serif;
      color: #fff; }
      @media (max-width: 768px) {
        .indexPage .mo-banner .banner-box h2 {
          letter-spacing: 0px; } }
    .indexPage .mo-banner .banner-box a {
      position: absolute; }
    .indexPage .mo-banner .banner-box span {
      margin-left: 18px; }
      @media (max-width: 768px) {
        .indexPage .mo-banner .banner-box span {
          margin-left: 0px;
          display: block; } }
  .indexPage .mo-banner .it {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0; }
    @media (max-width: 992px) {
      .indexPage .mo-banner .it {
        height: 48vh; } }
    .indexPage .mo-banner .it .it-bx {
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 668px;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%); }
      @media (max-width: 992px) {
        .indexPage .mo-banner .it .it-bx {
          width: 450px; } }
      @media (max-width: 576px) {
        .indexPage .mo-banner .it .it-bx {
          width: 330px; } }
      @media (max-width: 330px) {
        .indexPage .mo-banner .it .it-bx {
          width: 280px; } }
      .indexPage .mo-banner .it .it-bx img {
        opacity: 0;
        -webkit-transition: .4s;
        -o-transition: .4s;
        transition: .4s; }
      .indexPage .mo-banner .it .it-bx .box1 {
        opacity: 0;
        -webkit-transition: .4s;
        -o-transition: .4s;
        transition: .4s; }
  .indexPage .mo-banner .t1 .box1 {
    top: 28%;
    right: 25%; }
    @media (max-width: 576px) {
      .indexPage .mo-banner .t1 .box1 {
        right: 32%; } }
  .indexPage .mo-banner .t1 .arr_btn {
    right: 0px;
    bottom: -30px; }
  .indexPage .mo-banner .t2 {
    display: none; }
    .indexPage .mo-banner .t2 .box1 {
      top: 131px;
      right: 40px; }
      @media (max-width: 992px) {
        .indexPage .mo-banner .t2 .box1 {
          top: 90px;
          right: 43px; } }
      @media (max-width: 576px) {
        .indexPage .mo-banner .t2 .box1 {
          top: 55px;
          right: 25px; } }
    .indexPage .mo-banner .t2 .arr_btn {
      right: 0px;
      bottom: -30px; }
      @media (max-width: 992px) {
        .indexPage .mo-banner .t2 .arr_btn {
          right: 2px; } }
      @media (max-width: 576px) {
        .indexPage .mo-banner .t2 .arr_btn {
          bottom: -20px; } }
  .indexPage .mo-banner .t3 {
    display: none; }
    .indexPage .mo-banner .t3 .box1 {
      top: 135px;
      left: 50%;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%); }
      @media (max-width: 992px) {
        .indexPage .mo-banner .t3 .box1 {
          top: 85px; } }
      @media (max-width: 576px) {
        .indexPage .mo-banner .t3 .box1 {
          top: 45px;
          right: 25px;
          width: 100%;
          text-align: center; } }
    .indexPage .mo-banner .t3 .arr_btn {
      bottom: -30px;
      left: 50%;
      -webkit-transform: translateX(-42%);
          -ms-transform: translateX(-42%);
              transform: translateX(-42%); }
  .indexPage .mo-banner .t4 {
    display: none; }
    .indexPage .mo-banner .t4 .box1 {
      top: 135px;
      left: 50%;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%); }
      @media (max-width: 992px) {
        .indexPage .mo-banner .t4 .box1 {
          top: 85px; } }
      @media (max-width: 576px) {
        .indexPage .mo-banner .t4 .box1 {
          top: 55px; } }
    .indexPage .mo-banner .t4 .arr_btn {
      right: 0px;
      bottom: -30px; }

.indexPage .ind-bgv2 {
  background: #fcfaf6; }

.indexPage .ind-bg {
  background: #f6f6f6; }

.indexPage .item_box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 198px; }
  @media (max-width: 992px) {
    .indexPage .item_box {
      display: block;
      margin-top: 55px;
      padding: 0 25px; } }
  @media (max-width: 768px) {
    .indexPage .item_box {
      margin-top: 55px; } }

.indexPage .in-ltitle {
  font-size: 2.25rem;
  line-height: 0.32;
  letter-spacing: 1.8px;
  color: #000000;
  font-family: "Raleway", sans-serif;
  padding-top: 9px;
  opacity: 0;
  position: relative; }
  @media (max-width: 992px) {
    .indexPage .in-ltitle {
      margin-bottom: 50px;
      font-size: 1.4375rem; } }
  @media (max-width: 768px) {
    .indexPage .in-ltitle {
      margin-bottom: 50px; } }

.indexPage .ab-box {
  padding-bottom: 80px;
  padding-left: 55px;
  color: #000000;
  font-family: "Noto Sans TC", sans-serif;
  width: 815px;
  max-width: 77%;
  font-size: 0.9375rem;
  line-height: 2;
  letter-spacing: 0;
  position: relative;
  margin-left: 58px;
  opacity: 0;
  font-weight: 300; }
  .indexPage .ab-box::after {
    background: #f6f6f6; }
  @media (max-width: 992px) {
    .indexPage .ab-box {
      margin-left: 0px;
      max-width: 100%; } }
  @media (max-width: 768px) {
    .indexPage .ab-box {
      margin-left: 0px;
      max-width: 100%;
      padding-left: 15px;
      padding-bottom: 40px; } }
  .indexPage .ab-box .ch-text {
    margin-bottom: 32px;
    position: relative;
    top: -9px; }
    @media (max-width: 768px) {
      .indexPage .ab-box .ch-text {
        font-size: 0.875rem; } }
  @media (max-width: 768px) {
    .indexPage .ab-box .en-text {
      font-size: 0.875rem; } }
  .indexPage .ab-box .arr-box {
    position: absolute;
    bottom: 4px; }
    .indexPage .ab-box .arr-box .arr_btn::before {
      background: #bfbfbf; }
    .indexPage .ab-box .arr-box .arr_btn::after {
      background: #bfbfbf; }
    @media (max-width: 567px) {
      .indexPage .ab-box .arr-box {
        left: -2px; } }

.indexPage .pro-item {
  margin-top: 90px;
  padding-bottom: 235px; }
  @media (max-width: 992px) {
    .indexPage .pro-item {
      margin-top: 90px;
      padding-bottom: 43px; } }

.indexPage .pro-box {
  position: relative;
  opacity: 0;
  width: 775px;
  max-width: 76%;
  margin-left: 21px;
  padding: 130px 0 130px 65px; }
  .indexPage .pro-box::after {
    background: #f6f6f6; }
  @media (max-width: 992px) {
    .indexPage .pro-box {
      max-width: 100%;
      margin-left: 0; } }
  @media (max-width: 768px) {
    .indexPage .pro-box {
      padding: 0 60px 130px 60px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  @media (max-width: 576px) {
    .indexPage .pro-box {
      padding: 0 70px 65px 70px; } }
  @media (max-width: 350px) {
    .indexPage .pro-box {
      padding: 0 30px 65px 30px; } }
  .indexPage .pro-box .arr-box {
    position: absolute;
    bottom: 4px; }
    @media (max-width: 768px) {
      .indexPage .pro-box .arr-box {
        left: 0; } }
    .indexPage .pro-box .arr-box .arr_btn::before {
      background: #bfbfbf; }
    .indexPage .pro-box .arr-box .arr_btn::after {
      background: #bfbfbf; }
  .indexPage .pro-box .pc-proimg {
    display: block; }
    @media (max-width: 768px) {
      .indexPage .pro-box .pc-proimg {
        display: none; } }
  .indexPage .pro-box .mo-proimg {
    height: auto;
    width: 100%; }
    @media (min-width: 769px) {
      .indexPage .pro-box .mo-proimg {
        display: none; } }

.indexPage .in-bg {
  background: #fff; }

.indexPage .sur-bx {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 90px;
  margin-bottom: 90px;
  position: relative;
  opacity: 0; }
  @media (min-width: 992px) {
    .indexPage .sur-bx {
      max-width: 1050px;
      width: 100%;
      margin: 0 auto;
      margin-top: 90px;
      margin-bottom: 90px; } }
  @media (max-width: 992px) {
    .indexPage .sur-bx {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; } }
  @media (max-width: 576px) {
    .indexPage .sur-bx {
      margin-top: 70px;
      margin-bottom: 85px; } }
  .indexPage .sur-bx .item-product {
    width: 100%;
    height: 100%;
    position: absolute; }
  .indexPage .sur-bx .sur {
    padding-bottom: 65%;
    height: 0;
    width: 100%;
    position: relative; }
    @media (min-width: 768px) {
      .indexPage .sur-bx .sur:hover .h-textbox {
        opacity: 1; } }
  .indexPage .sur-bx .sur2 {
    padding-bottom: 123%;
    height: 0;
    width: 100%;
    position: relative; }
    @media (min-width: 768px) {
      .indexPage .sur-bx .sur2:hover .h-textbox {
        opacity: 1; } }
  .indexPage .sur-bx .pc-surbox {
    width: calc((100% - 30px) / 4); }
    @media (max-width: 992px) {
      .indexPage .sur-bx .pc-surbox {
        width: calc((100% - 30px) / 2); } }
    @media (max-width: 768px) {
      .indexPage .sur-bx .pc-surbox {
        width: 100%;
        margin: 12px 0; } }
    .indexPage .sur-bx .pc-surbox .h-textbox {
      background: rgba(0, 0, 0, 0.75);
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 5;
      -webkit-transition: .4s;
      -o-transition: .4s;
      transition: .4s;
      opacity: 0;
      font-weight: 300;
      font-family: "Raleway", sans-serif; }
      @media (max-width: 992px) {
        .indexPage .sur-bx .pc-surbox .h-textbox {
          opacity: 1; } }
      .indexPage .sur-bx .pc-surbox .h-textbox div {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        font-size: 1.125rem;
        color: #fff;
        text-decoration: none;
        text-align: center;
        width: 100%; }
        @media (max-width: 768px) {
          .indexPage .sur-bx .pc-surbox .h-textbox div {
            font-size: 0.875rem; } }
  .indexPage .sur-bx .martb {
    margin: 14px 0; }
    @media (max-width: 768px) {
      .indexPage .sur-bx .martb {
        margin: 0 0; } }
  .indexPage .sur-bx .mo-sur {
    display: none; }
    @media (max-width: 992px) {
      .indexPage .sur-bx .mo-sur {
        display: block; } }
  .indexPage .sur-bx .pc-sur {
    display: block; }
    @media (max-width: 992px) {
      .indexPage .sur-bx .pc-sur {
        display: none; } }

.indexPage .con-bx {
  margin-top: 68px;
  margin-bottom: 60px;
  position: relative;
  text-align: center;
  opacity: 0; }
  @media (max-width: 768px) {
    .indexPage .con-bx {
      margin-top: 45px;
      margin-bottom: 30px;
      top: 15px; } }
  .indexPage .con-bx h2 {
    font-size: 2.25rem;
    line-height: 0.32;
    letter-spacing: 1.8px;
    font-family: "Raleway", sans-serif;
    margin-bottom: 28px;
    color: #010101; }
    @media (max-width: 768px) {
      .indexPage .con-bx h2 {
        font-size: 1.4375rem;
        line-height: 0.25; } }
  .indexPage .con-bx p {
    font-size: 0.9375rem;
    line-height: 2;
    letter-spacing: 0.75px;
    font-family: "Raleway", sans-serif;
    color: #000000; }
    @media (max-width: 768px) {
      .indexPage .con-bx p {
        font-size: 0.875rem;
        line-height: 2; } }
  .indexPage .con-bx .arr_btn:before {
    background: #ac9f83; }
  .indexPage .con-bx .arr_btn:after {
    background: #ac9f83; }
  .indexPage .con-bx .arr-box {
    position: absolute;
    bottom: -49px;
    left: 50%;
    -webkit-transform: translatex(-50%);
        -ms-transform: translatex(-50%);
            transform: translatex(-50%); }
    @media (max-width: 768px) {
      .indexPage .con-bx .arr-box {
        bottom: -31px;
        left: 47%; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1200px; } }

.aboutPage h2 {
  font-size: 2.25rem;
  line-height: 0.32;
  letter-spacing: 1.8px;
  font-family: "Raleway", sans-serif;
  margin: 210px 0 125px 0;
  color: #000000; }
  @media (max-width: 1200px) {
    .aboutPage h2 {
      margin: 140px 0 50px 25px; } }
  @media (max-width: 768px) {
    .aboutPage h2 {
      margin: 140px 0 50px 25px;
      font-size: 1.375rem; } }

.aboutPage .about-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative; }
  @media (max-width: 1200px) {
    .aboutPage .about-box {
      display: block; } }

.aboutPage .left-text {
  font-size: 0.9375rem;
  line-height: 2;
  color: #000000;
  padding-left: 18px;
  position: relative;
  margin-right: 147px;
  height: 270px;
  width: 265px;
  opacity: 0; }
  .aboutPage .left-text::after {
    height: 8px; }
  @media (max-width: 1200px) {
    .aboutPage .left-text {
      margin-bottom: 90px;
      margin-right: 0px;
      margin-left: 25px;
      font-size: 0.875rem; } }
  @media (max-width: 768px) {
    .aboutPage .left-text {
      margin-bottom: 70px; }
      .aboutPage .left-text:after {
        position: absolute;
        content: '';
        width: 5px;
        height: 25px;
        background: #ffffff;
        left: -3px;
        bottom: -1px; } }
  @media (max-width: 576px) {
    .aboutPage .left-text {
      margin-bottom: 70px;
      margin-left: 10px;
      padding-left: 15px;
      width: 260px; } }

.aboutPage .ch-box {
  font-size: 0.9375rem;
  margin-bottom: 29px;
  position: relative;
  top: -6px;
  font-weight: 500;
  letter-spacing: 0; }
  @media (max-width: 768px) {
    .aboutPage .ch-box {
      font-size: 0.875rem;
      margin-bottom: 25px; } }
  @media (max-width: 567px) {
    .aboutPage .ch-box {
      font-weight: 500; } }

.aboutPage .en-box {
  font-size: 0.9375rem;
  letter-spacing: 0; }
  @media (max-width: 768px) {
    .aboutPage .en-box {
      font-size: 0.875rem; } }

.aboutPage .ab-img {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 80px; }
  @media (max-width: 768px) {
    .aboutPage .ab-img {
      display: block;
      margin-bottom: 70px; } }

.aboutPage .right-box {
  width: 769px;
  position: relative;
  opacity: 0; }
  @media (max-width: 1200px) {
    .aboutPage .right-box {
      width: 100%;
      padding: 0 25px; } }
  @media (max-width: 768px) {
    .aboutPage .right-box {
      max-width: 769px;
      padding: 0 10px; } }

.aboutPage .img-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .aboutPage .img-card:not(:last-child) {
    margin-right: 110px; }
  .aboutPage .img-card div {
    text-align: center; }
    .aboutPage .img-card div:not(:last-child) {
      margin-right: 110px; }
    @media (max-width: 992px) {
      .aboutPage .img-card div:not(:last-child) {
        margin-right: 100px; } }
    @media (max-width: 769px) {
      .aboutPage .img-card div {
        min-width: 120px;
        text-align: center;
        margin-right: 70px !important; } }
    @media (max-width: 340px) {
      .aboutPage .img-card div {
        margin-right: 35px !important; } }
  .aboutPage .img-card p {
    color: #000000;
    font-size: 0.9375rem;
    font-family: "Noto Sans TC", sans-serif;
    margin-top: 20px;
    letter-spacing: 0; }
    @media (max-width: 768px) {
      .aboutPage .img-card p {
        font-size: 0.875rem; } }
  @media (max-width: 992px) {
    .aboutPage .img-card:not(:last-child) {
      margin-right: 100px; } }
  @media (max-width: 768px) {
    .aboutPage .img-card:not(:last-child) {
      margin-bottom: 45px;
      margin-right: 0; } }

.aboutPage .right-textbox {
  color: #000000;
  font-size: 0.9375rem;
  letter-spacing: 0;
  margin-bottom: 65px; }
  .aboutPage .right-textbox p {
    line-height: 2; }
    .aboutPage .right-textbox p:not(:last-child) {
      margin-bottom: 33px; }
      @media (max-width: 567px) {
        .aboutPage .right-textbox p:not(:last-child) {
          margin-bottom: 35px; } }
  .aboutPage .right-textbox .ch {
    padding-bottom: 50px;
    border-bottom: 1px solid #dcdcdc;
    margin-bottom: 50px; }
    @media (max-width: 768px) {
      .aboutPage .right-textbox .ch {
        margin-bottom: 35px;
        padding-bottom: 35px; } }
  @media (max-width: 768px) {
    .aboutPage .right-textbox {
      margin-bottom: 35px;
      font-size: 0.875rem; } }

@media (max-width: 992px) {
  .processPage main {
    padding-bottom: 0; } }

@media (max-width: 992px) {
  .processPage .title-box {
    padding: 0 15px; } }

@media (max-width: 800px) {
  .processPage .title-box {
    padding: 0 22px; } }

.processPage h2 {
  font-size: 2.25rem;
  line-height: 0.32;
  letter-spacing: 1.8px;
  font-family: "Raleway", sans-serif;
  margin: 210px 0 125px 0;
  color: black; }
  @media (max-width: 768px) {
    .processPage h2 {
      margin: 140px 0 60px 0;
      font-size: 1.5rem; } }

.processPage .process-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0 22px; }
  @media (max-width: 1200px) {
    .processPage .process-box {
      display: block; } }

.processPage .top-menu-ul {
  margin-right: 185px;
  opacity: 0;
  position: relative;
  letter-spacing: 0; }
  @media (min-width: 1200px) {
    .processPage .top-menu-ul li:not(:last-child) {
      position: relative;
      top: -5px;
      margin-bottom: 30px;
      width: 130px; } }
  @media (max-width: 1200px) {
    .processPage .top-menu-ul ul li br {
      display: none; } }

.processPage .right-list {
  width: 846px;
  color: #000000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  position: relative; }
  @media (min-width: 1200px) {
    .processPage .right-list {
      top: -4px; } }
  @media (max-width: 1200px) {
    .processPage .right-list {
      width: 100%;
      max-width: 772px;
      margin: 0 auto; } }
  .processPage .right-list .top-box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-bottom: 13px;
    border-bottom: 1px solid #000000; }
    @media (max-width: 768px) {
      .processPage .right-list .top-box {
        padding-bottom: 9px; } }
  .processPage .right-list .step-card {
    width: calc((100% - 80px) / 3);
    line-height: 2;
    font-size: 0.9375rem;
    letter-spacing: 0;
    height: 310px;
    overflow: hidden;
    margin-bottom: 5px;
    padding-top: 16px;
    opacity: 0;
    position: relative; }
    @media (max-width: 992px) {
      .processPage .right-list .step-card {
        width: calc((100% - 80px) / 2); } }
    @media (max-width: 576px) {
      .processPage .right-list .step-card {
        width: 100%;
        padding: 16px 0px 60px 0px;
        height: auto;
        font-size: 0.875rem; } }
  .processPage .right-list .num {
    font-family: "Mukta", sans-serif;
    font-size: 3.5625rem;
    font-weight: 200;
    line-height: 0.2;
    letter-spacing: 2.85px; }
    @media (max-width: 768px) {
      .processPage .right-list .num {
        font-size: 3.125rem; } }
  .processPage .right-list .step {
    font-size: 0.875rem;
    letter-spacing: 0.7px;
    font-family: "Raleway", sans-serif; }
    @media (max-width: 768px) {
      .processPage .right-list .step {
        font-size: 0.75rem; } }
  .processPage .right-list .type {
    font-size: 0.9375rem;
    line-height: 2.67;
    color: #7d7d7d;
    margin: 4px 0 21px 0; }
    @media (max-width: 567px) {
      .processPage .right-list .type {
        margin: 4px 0 15px 0;
        font-size: 0.875rem; } }

@media (max-width: 1200px) {
  .processPage .left-line {
    border-left: none; }
    .processPage .left-line::before {
      position: absolute;
      content: '';
      width: 3px;
      height: 27px;
      background: none;
      top: 0;
      left: -4px; } }
  @media (max-width: 1200px) and (max-width: 768px) {
    .processPage .left-line::before {
      width: 2px;
      height: 17px;
      left: -3px; } }

.processPage .fade1 {
  -webkit-animation: blockfade .4s .4s linear forwards;
          animation: blockfade .4s .4s linear forwards; }

.processPage .fade2 {
  -webkit-animation: blockfade .4s .8s linear forwards;
          animation: blockfade .4s .8s linear forwards; }

.processPage .fade3 {
  -webkit-animation: blockfade .4s 1.2s linear forwards;
          animation: blockfade .4s 1.2s linear forwards; }

.processPage .fade4 {
  -webkit-animation: blockfade .4s 1.6s linear forwards;
          animation: blockfade .4s 1.6s linear forwards; }

.processPage .fade5 {
  -webkit-animation: blockfade .4s 2s linear forwards;
          animation: blockfade .4s 2s linear forwards; }

.processPage .fade6 {
  -webkit-animation: blockfade .4s 2.4s linear forwards;
          animation: blockfade .4s 2.4s linear forwards; }

.menu_fade {
  -webkit-animation: blockfade .4s  linear forwards;
          animation: blockfade .4s  linear forwards; }

.text-fade {
  -webkit-animation: blockfade .4s .4s linear forwards;
          animation: blockfade .4s .4s linear forwards; }

@-webkit-keyframes blockfade {
  0% {
    opacity: 0;
    top: 10px; }
  100% {
    top: 0;
    opacity: 1; } }

@keyframes blockfade {
  0% {
    opacity: 0;
    top: 10px; }
  100% {
    top: 0;
    opacity: 1; } }

.processPage2 .right-list {
  -webkit-box-pack: start;
  -webkit-justify-content: start;
      -ms-flex-pack: start;
          justify-content: start; }

@media (min-width: 576px) {
  .processPage2 .mr {
    margin-right: 40px; } }

@media (min-width: 579px) and (max-width: 997px) {
  .processPage2 .tmr {
    margin-right: 40px; } }

.active {
  color: #e41b5b !important; }

.arr_btn {
  width: 68px;
  height: 15px;
  display: block; }
  .arr_btn:before {
    content: '';
    position: absolute;
    width: 68px;
    height: 1px;
    background: #ffffff;
    bottom: 0;
    left: 0;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s; }
    @media (max-width: 768px) {
      .arr_btn:before {
        width: 44px;
        left: auto;
        right: 0; } }
  .arr_btn:after {
    content: '';
    position: absolute;
    width: 18px;
    height: 1px;
    background: #ffffff;
    bottom: 6px;
    right: -2px;
    -webkit-transform: rotate(42deg);
        -ms-transform: rotate(42deg);
            transform: rotate(42deg);
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s; }
    @media (max-width: 768px) {
      .arr_btn:after {
        width: 15px;
        bottom: 5px; } }
  @media (min-width: 768px) {
    .arr_btn:hover:before {
      width: 86px; }
    .arr_btn:hover:after {
      right: -20px; } }

.left-line {
  border-left: 1px solid #e41b5b; }
  .left-line::before {
    position: absolute;
    content: '';
    width: 3px;
    height: 27px;
    background: #e41b5b;
    top: 0;
    left: -4px; }
  .left-line::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -1px;
    width: 5px;
    height: 5px;
    background: #ffffff; }
  @media (max-width: 768px) {
    .left-line::before {
      width: 1px;
      height: 17px;
      left: -2px; } }

@media (max-width: 1200px) {
  .mo_topline {
    border-top: 1px solid  #e41b5b;
    display: block;
    width: 772px;
    max-width: 100%;
    margin: 0 auto; }
    .mo_topline::before {
      position: absolute;
      content: '';
      width: 19px;
      height: 3px;
      top: -4px;
      left: 0;
      background: #e41b5b; } }
  @media (max-width: 1200px) and (max-width: 576px) {
    .mo_topline::before {
      height: 1px;
      top: -2px; } }
  @media (max-width: 1200px) and (max-width: 414px) {
    .mo_topline {
      display: block;
      width: 100%; } }

@media (max-width: 1200px) {
  #top-menu-ul {
    position: relative;
    z-index: 1;
    padding-top: 10px; } }
  @media (max-width: 1200px) and (max-width: 991.99px) {
    #top-menu-ul {
      margin: 0 auto 35px; } }
  @media (max-width: 1200px) and (min-width: 992px) {
    #top-menu-ul {
      margin: 0 auto 50px; } }

@media (max-width: 1200px) {
    #top-menu-ul .item_Menu {
      margin: 0 auto;
      overflow: hidden;
      height: 30px; }
    #top-menu-ul .item_menu_Box {
      width: 100%;
      overflow: hidden;
      overflow-x: scroll;
      position: relative;
      text-align: left; }
      #top-menu-ul .item_menu_Box .item_menu_list {
        white-space: nowrap !important;
        padding-bottom: 0px;
        text-align: center;
        font-size: 0px;
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex; }
      #top-menu-ul .item_menu_Box li {
        height: 30px; }
        #top-menu-ul .item_menu_Box li:not(:last-child) {
          margin: 0 27px 0 0; }
      #top-menu-ul .item_menu_Box li a {
        position: relative;
        display: block;
        line-height: 1;
        letter-spacing: 0.6px;
        font-size: 0.875rem;
        color: #000; } }
      @media (max-width: 1200px) and (max-width: 991.99px) {
        #top-menu-ul .item_menu_Box li a {
          padding: 7px 0 3px; } }
      @media (max-width: 1200px) and (min-width: 992px) {
        #top-menu-ul .item_menu_Box li a {
          padding: 7px 0; } }
      @media (max-width: 1200px) and (max-width: 567px) {
        #top-menu-ul .item_menu_Box li a {
          letter-spacing: 0px; } }

@media (max-width: 1200px) {
      #top-menu-ul .item_menu_Box li.active a {
        border-color: black;
        color: black; }
  .flex-direction-nav {
    display: none; }
    .flex-direction-nav a {
      display: block;
      width: 18px;
      height: 18px;
      position: absolute;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      top: 50%;
      z-index: 10; }
      .flex-direction-nav a:after {
        position: absolute;
        top: 0; }
    .flex-direction-nav a.flex-next {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAdCAMAAACUsxyNAAAAM1BMVEVHcEy1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbWKanJWAAAAEHRSTlMA3eg9HMEp9g5OjJrNpbeB+N++qAAAAEhJREFUGNOlzzESgCAQQ9EF2UVQIfc/La1BZhgw3X9dxDQITeFYwt3LuSc6EuvFb0iZi3xFY6I+Ip6ffb07rXdeanAbUPlh5W76TQRcPMBYlQAAAABJRU5ErkJggg==) no-repeat center/15px;
      right: -18px; }
    .flex-direction-nav a.flex-prev {
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAeBAMAAAA4FegcAAAAJ1BMVEVHcEy1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbW1tbVz3096AAAADHRSTlMA+1R2YmyBj0If9vCHi8VWAAAAb0lEQVQY02NggIAJUJqBbQeMZSMEZTAfVICyYo5AGaxnHKAsn6NQBsuZAChL8TCUwSRjABM6BhdKgLIaj0MZHGsKoKxZMCEGdnEGDA0IQ5AMRliG5ACEo5AcinA8kocQnmRgQwjmwAXZD8IDppgBAHNjGMBwMz8pAAAAAElFTkSuQmCC) no-repeat center/15px;
      left: -18px; }
  .open_flexslider .flex-direction-nav {
    display: block; } }

li {
  list-style: none !important; }

.item_menu_list {
  position: relative;
  padding-left: 20px; }
  @media (max-width: 1200px) {
    .item_menu_list {
      padding-left: 0px; } }
  .item_menu_list li a {
    color: #000000;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    text-decoration: none;
    line-height: 1.56;
    font-size: 1rem; }
    @media (min-width: 992px) {
      .item_menu_list li a:hover {
        color: #e41b5b; } }
  .item_menu_list li:not(:last-child) {
    margin-bottom: 35px; }

.shareh2 {
  font-size: 2.25rem;
  line-height: 0.32;
  letter-spacing: 1.8px;
  font-family: "Raleway", sans-serif;
  margin: 210px 0 125px;
  color: black; }
  @media (max-width: 768px) {
    .shareh2 {
      margin: 140px 0 50px 0; } }
  @media (max-width: 567px) {
    .shareh2 {
      font-size: 1.4375rem; } }

.page {
  text-align: left;
  font-size: 0.9375rem;
  font-family: "Noto Sans TC", sans-serif;
  margin-bottom: 70px; }
  @media (max-width: 1200px) {
    .page {
      text-align: center; } }
  @media (max-width: 567px) {
    .page {
      position: relative; } }
  .page dd a {
    color: #000000;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s; }
    @media (min-width: 768px) {
      .page dd a:hover {
        color: #e41b5b; } }
  .page dd {
    width: 5.3%; }
    @media (max-width: 567px) {
      .page dd {
        width: 15.3%; } }
    @media (max-width: 340px) {
      .page dd {
        width: 14.3%; } }
  .page .active a {
    color: #e41b5b !important;
    font-weight: 400 !important; }

.page .ltbn {
  margin-right: 15px; }
  @media (max-width: 1200px) {
    .page .ltbn {
      margin-right: 0; } }
  @media (max-width: 567px) {
    .page .ltbn {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      left: 0; } }

@media (min-width: 992px) {
  .page .nopage a:hover {
    background: #000000 !important; }
    .page .nopage a:hover::before {
      background: #000000 !important; } }

.page dt.ltbn a {
  border-radius: 0;
  background: #000000;
  height: 1px;
  width: 20px;
  opacity: 1;
  position: relative;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  top: 4px; }
  @media (min-width: 992px) {
    .page dt.ltbn a:hover {
      background: #e41b5b; }
      .page dt.ltbn a:hover::before {
        background: #e41b5b; } }
  @media (max-width: 1200px) {
    .page dt.ltbn a {
      margin-right: 0; } }
  .page dt.ltbn a::before {
    border: none;
    width: 8px;
    height: 1px;
    background: #000000;
    position: absolute;
    top: -3px;
    left: 0;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    -webkit-transform: rotate(-42deg);
        -ms-transform: rotate(-42deg);
            transform: rotate(-42deg); }
  .page dt.ltbn a div {
    height: 20px;
    position: relative;
    top: -10px; }

.page dt.rtbn a {
  border-radius: 0;
  background: #000000;
  height: 1px;
  width: 20px;
  opacity: 1;
  position: relative;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  margin-left: 0;
  top: 4px; }
  @media (min-width: 992px) {
    .page dt.rtbn a:hover {
      background: #e41b5b; }
      .page dt.rtbn a:hover::before {
        background: #e41b5b; } }
  @media (max-width: 567px) {
    .page dt.rtbn a {
      position: absolute;
      top: 62%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      right: 0; } }
  .page dt.rtbn a::before {
    border: none;
    width: 8px;
    height: 1px;
    background: #000000;
    position: absolute;
    top: -3px;
    right: 0;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    -webkit-transform: rotate(42deg);
        -ms-transform: rotate(42deg);
            transform: rotate(42deg); }
  .page dt.rtbn a div {
    height: 20px;
    position: relative;
    top: -10px; }

@media (min-width: 1200px) {
  .stoph2 {
    display: none; }
  .stopmenu .top-menu-ul {
    position: absolute !important;
    bottom: 0;
    opacity: 1 !important;
    top: auto !important; } }

.showmuh2 {
  display: block !important;
  margin-bottom: 140px; }

@media (max-width: 1200px) {
  .title-box {
    max-width: 800px;
    width: 100%;
    margin: 0 auto; } }

@media (max-width: 567px) {
  .title-box {
    padding: 0 22px; } }

.portfolioPage main {
  padding-bottom: 0; }

@media (min-width: 568px) and (max-width: 992px) {
  .portfolioPage .container {
    padding: 0 25px; } }

.portfolioPage .portfolio-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  @media (min-width: 1200px) {
    .portfolioPage .portfolio-box {
      margin-top: 200px; } }
  @media (max-width: 1200px) {
    .portfolioPage .portfolio-box {
      display: block; } }
  @media (max-width: 567px) {
    .portfolioPage .portfolio-box {
      padding: 0 22px; } }

@media (min-width: 1200px) {
  .portfolioPage .shareh2 {
    position: fixed; } }

.portfolioPage .item_menu_Box .shareh2 {
  position: relative;
  display: none;
  left: -5px; }

.portfolioPage .top-menu-ul {
  margin-right: 265px;
  position: relative;
  opacity: 1;
  -webkit-transition: .4s;
  -o-transition: .4s;
  transition: .4s;
  letter-spacing: 0; }
  @media (min-width: 1200px) {
    .portfolioPage .top-menu-ul {
      position: fixed;
      top: 362px;
      margin-left: 5px; }
      .portfolioPage .top-menu-ul li {
        position: relative;
        top: -5px;
        margin-bottom: 30px; }
        .portfolioPage .top-menu-ul li .active {
          -webkit-animation: none !important;
                  animation: none !important; } }

.portfolioPage .right-box {
  width: 772px;
  min-height: 432px; }
  @media (min-width: 1200px) {
    .portfolioPage .right-box {
      position: relative;
      left: 34%; } }
  @media (max-width: 1200px) {
    .portfolioPage .right-box {
      max-width: 772px;
      width: 100%;
      margin: 0 auto; } }
  .portfolioPage .right-box .pc-surbox {
    position: relative;
    opacity: 0;
    margin-bottom: 100px;
    top: 10px;
    -webkit-transition: all 0.6s;
    -o-transition: all 0.6s;
    transition: all 0.6s; }
    @media (min-height: 768px) {
      .portfolioPage .right-box .pc-surbox:nth-child(3) {
        -webkit-transition-delay: .2s;
             -o-transition-delay: .2s;
                transition-delay: .2s; }
      .portfolioPage .right-box .pc-surbox:nth-child(4) {
        -webkit-transition-delay: .2s;
             -o-transition-delay: .2s;
                transition-delay: .2s; } }
    @media (max-width: 768px) {
      .portfolioPage .right-box .pc-surbox:nth-child(even) {
        -webkit-transition-delay: .2s;
             -o-transition-delay: .2s;
                transition-delay: .2s; } }
    .portfolioPage .right-box .pc-surbox.is-active {
      opacity: 1;
      top: 0; }
    @media (max-width: 768px) {
      .portfolioPage .right-box .pc-surbox:not(:last-child) {
        margin-bottom: 20px; } }

@-webkit-keyframes fade1 {
  0% {
    opacity: 0;
    top: 10px; }
  100% {
    top: 0;
    opacity: 1; } }

@keyframes fade1 {
  0% {
    opacity: 0;
    top: 10px; }
  100% {
    top: 0;
    opacity: 1; } }
    .portfolioPage .right-box .pc-surbox a {
      display: block;
      text-decoration: none; }
      @media (min-width: 992px) {
        .portfolioPage .right-box .pc-surbox a:hover .sur {
          opacity: .5; } }
  .portfolioPage .right-box .sur {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAp0AAAFQBAMAAAAPZdj6AAAAElBMVEXU1NTU1NTU1NTU1NT////q6uoeRwVBAAAAA3RSTlO7WrS/JRdkAAAEGElEQVR42u3c25GbMBgGUM+kge0hJWAqAFUA6r+VxPIFy4AMa+9EuznfgzOgycuZX1c0e/g4yPvycfjVyPvycfgNgSdPnsKTJ0/hyZOn8OTJk6fw5MlTePLkKTx58uQpPHnyFJ48eQpPnjx5Ck+ePIUnT57CkydPnhB48uQpPHnyFJ48eQpPnjx5Ck+ePIUnT57CkydPnsKTJ0/hyZOn8OTJk6fw5MlTePLkKTx58uQJgSdPnsKTJ0/hyZOn8OTJk6fw5MlTePLkKTx58uQpPHnyFJ48eQpPnjx5Ck+ePIUnT57CkyfPl9OOfR8/0cZzmaxPGZbaYmoKPHfkzLmI1hbaeC5nvJj13Sr1SvHyLJTg38zajremwHNjJrN5gY79NyjQyjwnslkR3pXu0mDAs9zdZx3+WLDm+bS7z4pwLFjzfDK7L3jeN9U7gFbsGQqeHc+d09GjZ8uTZ1WefcEz8FSf6vO/8VSf710vqc/9nr3151v3m/322uW54TwkbN/b89wyIW235rmlwxcGV/W5v8OHgrX63DvDd/NDzptmVJ+7R9Bm7nn7Xtyqz709fmgWzMZLX2/V5w7Qv2phaJqljxon7Hj6R31+ouMXalh97q/PcHtaqc/Y13c7rOLxs5+eFsfPscaVU93ze34DLJ/fj1UuRStef4bH22H5+rPOb8cV74/6h9th+f6o0tthVe/f8xtg+f690ssiFZ8vhfx2WH6+VOvtsIrPP/u88+fnn7UejtR8Pp89Dfn5fK23w2r+fpTfDsu/x9V6O6zm75s9zzdORw8JhaeO54uePc8XPXv1+aJnUJ/q85vWJ0/rpX/t2RXW8wPP3fvNobDfbOw3d5+HNOvnIaFxHrJ3QgrN+nld1ziv29vhu2b9PHlonCfv7fCzp/wLh+8d+2b4Lnsa5k++x+0ZQR++EBeeuobn0x4fhqz/523ZWOs+w4YuP92iaTc/8fyJ4cmTJ0/54Z4Dzy/0bHluTExwaVnZxrsXp992artr51naHg2n/eMxycVjehHTi9PreCrLY3OV7eLtP/BcyTF17OH8mzxT327TizheKVN/H6YWnmv12aRCTEjdVJ/n18OF/IzaXrx5ljxjl3bmzbUcLyNn+o3xOmqm+jw/8tw8kT+bxSv809TV1ec4ecawZSnA0/5IePLkKTx58uQpPHnyFJ48eQpPnjx58uTJk6fw5MlTePLkKTx58uQpPHnyFJ48eQpPnjx5Ck+ePIUnT57CkydPnsKTJ0/hyZOn8OTJkydPnjx5Ck+ePIUnT57CkydPnsKTJ0/hyZOn8OTJk6fw5MlTePLkKTx58uQpPHnyFJ48eQpPnjx58uTJk6fw5MlTePLkKTx58uQpPHnyFJ48eQpPnjx5Ck+ePIUnT57C84s9/wCHUadJCJuvKgAAAABJRU5ErkJggg==) no-repeat;
    background-size: cover;
    padding-bottom: 50%;
    height: 0;
    width: 100%;
    position: relative;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    cursor: pointer; }
  .portfolioPage .right-box .item-product {
    width: 100%;
    height: 100%;
    position: absolute; }
  .portfolioPage .right-box .h-textbox {
    color: #000000;
    font-size: 0.9375rem;
    line-height: 2;
    font-family: "Noto Sans TC", sans-serif;
    margin-top: 16px;
    letter-spacing: 0;
    font-weight: 400; }
    @media (max-width: 768px) {
      .portfolioPage .right-box .h-textbox {
        font-size: 0.875rem; } }
    @media (max-width: 567px) {
      .portfolioPage .right-box .h-textbox {
        margin-top: 5px; } }

@media (max-width: 1200px) {
  .portfolioPage .left-line {
    border-left: none; }
    .portfolioPage .left-line::before {
      position: absolute;
      content: '';
      width: 3px;
      height: 27px;
      background: none;
      top: 0;
      left: -4px; } }
  @media (max-width: 1200px) and (max-width: 768px) {
    .portfolioPage .left-line::before {
      width: 2px;
      height: 17px;
      left: -3px; } }

@media (max-width: 768px) {
  .portfolioPage #top-menu-ul {
    margin-bottom: 40px !important; } }

@media (min-width: 1200px) {
  .portfinsidePage .shareh2 {
    position: relative; }
  .portfinsidePage .portfolio-box {
    margin-top: 0px; } }

@media (min-width: 568px) and (max-width: 992px) {
  .portfinsidePage .container {
    padding: 0 25px; } }

.portfinsidePage .editor_Content {
  margin-bottom: 100px; }

.portfinsidePage .portfolio-box {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  opacity: 0;
  -webkit-animation: porimgfade .4s linear forwards;
          animation: porimgfade .4s linear forwards; }
  @media (max-width: 1200px) {
    .portfinsidePage .portfolio-box {
      text-align: center; } }
  .portfinsidePage .portfolio-box img {
    margin-bottom: 45px; }
  @media (max-width: 567px) {
    .portfinsidePage .portfolio-box {
      padding: 0 22px; } }

.portfinsidePage .inside-title {
  position: relative;
  opacity: 0;
  -webkit-animation: porimgfade .4s .4s linear forwards;
          animation: porimgfade .4s .4s linear forwards; }

@-webkit-keyframes porimgfade {
  0% {
    opacity: 0;
    top: 5px; }
  100% {
    opacity: 1;
    top: 0; } }

@keyframes porimgfade {
  0% {
    opacity: 0;
    top: 5px; }
  100% {
    opacity: 1;
    top: 0; } }

.portfinsidePage .editor_Content {
  padding-top: 40px;
  border-top: 1px solid #dcdcdc;
  line-height: 2;
  letter-spacing: 0; }

@media (max-width: 1200px) {
  .portfinsidePage .title-box {
    max-width: 1000px; }
  .portfinsidePage .shareh2 {
    margin: 140px 0 50px; } }

.portfinsidePage .arr_btn::before {
  background: #575757;
  bottom: 0px;
  left: 58px;
  width: 47px; }

.portfinsidePage .arr_btn::after {
  background: #575757;
  bottom: 6px;
  right: 0px; }

@media (max-width: 768px) {
  .portfinsidePage .arr_btn::before {
    width: 35px;
    left: 50px; }
  .portfinsidePage .arr_btn::after {
    bottom: 5px; } }

@media (min-width: 768px) {
  .portfinsidePage .arr_btn:hover::before {
    width: 57px; }
  .portfinsidePage .arr_btn:hover::after {
    right: -10px; } }

.inside-title {
  color: #000000;
  font-family: "Noto Sans TC", sans-serif;
  letter-spacing: 0;
  position: relative;
  padding-left: 15px;
  margin-bottom: 40px;
  margin-left: 2px; }
  .inside-title:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: -1px;
    background: #ffffff;
    width: 5px;
    height: 3px; }
  @media (min-width: 768px) {
    .inside-title {
      margin-left: 3px; } }
  .inside-title div {
    font-size: 1rem;
    line-height: 2.16;
    position: relative;
    top: -5px; }
    @media (max-width: 768px) {
      .inside-title div {
        font-size: 0.875rem; } }
  .inside-title h3 {
    font-size: 2.25rem;
    line-height: 0.83;
    font-weight: 400; }
    @media (max-width: 768px) {
      .inside-title h3 {
        font-size: 1.25rem; } }

.inside-box {
  padding: 0 15px; }
  @media (max-width: 1200px) {
    .inside-box {
      width: 100%;
      max-width: 1000px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
          -ms-flex: 0 0 100%;
              flex: 0 0 100%;
      margin: 0 auto; } }
  @media (max-width: 567px) {
    .inside-box {
      padding: 0 22px; } }

.back {
  position: relative;
  letter-spacing: 0.9px;
  font-size: 1.125rem;
  width: 108px; }
  .back a {
    color: #575757;
    text-decoration: none;
    display: block;
    width: 112px;
    position: relative; }
  @media (max-width: 768px) {
    .back {
      width: 87px;
      font-size: 1rem; } }

.articleinsidePage .editor_Content {
  margin-bottom: 100px;
  color: #000000;
  padding-top: 0px; }

.articleinsidePage .inside-title {
  position: relative;
  opacity: 0;
  -webkit-animation: insidefade .4s linear forwards;
          animation: insidefade .4s linear forwards; }

@-webkit-keyframes insidefade {
  0% {
    opacity: 0;
    top: 10px; }
  100% {
    opacity: 1;
    top: 0; } }

@keyframes insidefade {
  0% {
    opacity: 0;
    top: 10px; }
  100% {
    opacity: 1;
    top: 0; } }
  .articleinsidePage .inside-title h3 {
    font-size: 1.25rem;
    font-weight: 400; }
    @media (max-width: 768px) {
      .articleinsidePage .inside-title h3 {
        font-size: 1.0625rem; } }
  .articleinsidePage .inside-title .inside-nct {
    margin-left: 10px;
    padding-left: 10px;
    position: relative; }
    .articleinsidePage .inside-title .inside-nct::before {
      width: 1px;
      height: 17px;
      background: #000000;
      position: absolute;
      content: '';
      top: 4px;
      left: 0px; }

.articleinsidePage .editor_Content {
  border-top: none;
  line-height: 2;
  letter-spacing: 0; }

.articlePage .container {
  padding: 0 22px; }

@media (max-width: 1200px) {
  .articlePage .shareh2 {
    margin: 140px 0 50px; }
  .articlePage #top-menu-ul {
    margin-bottom: 35px !important; } }

@media (max-width: 768px) {
  .articlePage .shareh2 {
    font-size: 1.5rem; }
  .articlePage #top-menu-ul {
    margin-bottom: 50px !important; } }

.articlePage main {
  padding-bottom: 0px; }

.articlePage .right-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  min-height: 402px; }
  @media (min-width: 1200px) {
    .articlePage .right-box {
      margin-top: 160px; } }

.articlePage .pc-surbox {
  width: calc((100% - 30px) / 2);
  margin-bottom: 70px !important; }
  @media (min-width: 769px) {
    .articlePage .pc-surbox:hover .sur {
      opacity: .5; } }
  @media (max-width: 768px) {
    .articlePage .pc-surbox {
      width: 100%;
      margin-bottom: 0px !important; } }
  .articlePage .pc-surbox a {
    text-decoration: none; }
  .articlePage .pc-surbox .sur {
    padding-bottom: 65%;
    background-position: center; }
  .articlePage .pc-surbox .h-textbox div {
    line-height: 1.85;
    letter-spacing: 0; }
  .articlePage .pc-surbox .h-textbox .text-bx {
    height: 48px;
    overflow: hidden;
    font-weight: 400; }
    @media (max-width: 768px) {
      .articlePage .pc-surbox .h-textbox .text-bx {
        margin-bottom: 17px; } }

@media (min-width: 1200px) {
  .articlePage .stopmenu .top-menu-ul {
    bottom: 30px; } }

@media (max-width: 768px) {
  .contactPage .con-h2 {
    margin-left: 10px; } }

.contactPage .left-text {
  width: 245px;
  height: 88px;
  font-size: 0.9375rem;
  font-family: "Noto Sans TC", sans-serif;
  line-height: 2;
  letter-spacing: 0;
  margin-top: 15px; }
  .contactPage .left-text::after {
    height: 13px; }
  .contactPage .left-text br {
    display: none; }
  .contactPage .left-text p {
    position: relative;
    top: -8px;
    font-weight: 400; }
  @media (max-width: 768px) {
    .contactPage .left-text {
      height: auto;
      width: 93%;
      margin-left: 10px;
      margin-bottom: 60px; }
      .contactPage .left-text br {
        display: block; }
      .contactPage .left-text p {
        position: relative;
        top: -10px; }
      .contactPage .left-text::after {
        height: 20px; } }

.contactPage .per-card {
  font-family: "Noto Sans TC", sans-serif;
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 2.8;
  letter-spacing: 0.75px;
  color: #000000; }
  @media (max-width: 768px) {
    .contactPage .per-card {
      font-size: 0.875rem;
      margin-bottom: 50px; } }
  .contactPage .per-card a {
    color: #000000;
    text-decoration: none;
    margin-left: 10px; }
    @media (max-width: 768px) {
      .contactPage .per-card a {
        position: relative;
        top: 3px; } }
    .contactPage .per-card a:nth-child(3) {
      margin-left: 11px; }
  .contactPage .per-card .mailml {
    margin-left: 11px; }
  .contactPage .per-card span {
    margin-left: 6px;
    line-height: 2; }
    @media (max-width: 768px) {
      .contactPage .per-card span {
        position: relative;
        top: 3px; } }
  .contactPage .per-card .loml {
    margin-left: 31px;
    line-height: 1.8;
    display: block;
    width: 335px; }
    @media (max-width: 768px) {
      .contactPage .per-card .loml {
        letter-spacing: 0; } }
    @media (max-width: 576px) {
      .contactPage .per-card .loml {
        width: auto; } }
  .contactPage .per-card .map-img {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .contactPage .per-card ul li:last-child {
    line-height: 2;
    margin-top: 5px; }

.contactPage .right-tbox {
  margin-bottom: 50px; }
  @media (max-width: 768px) {
    .contactPage .right-tbox {
      margin-bottom: 60px; } }

.contactPage .right-bbox {
  padding-top: 60px;
  border-top: 1px solid #dcdcdc; }
  .contactPage .right-bbox form {
    color: #000000;
    font-size: 0.9375rem;
    font-family: "Noto Sans TC", sans-serif;
    letter-spacing: 0.75px; }
    .contactPage .right-bbox form input {
      border-bottom: 1px solid #dcdcdc;
      height: 30px;
      border-radius: 0px;
      padding: 0 0;
      margin-left: 6px;
      margin-top: 5px; }
      @media (max-width: 567px) {
        .contactPage .right-bbox form input {
          margin-top: 15px; } }
      .contactPage .right-bbox form input:focus {
        border-bottom: 1px solid #dcdcdc;
        border-top: none;
        border-left: none;
        border-right: none; }
    .contactPage .right-bbox form .note {
      color: #e41b5b; }
    .contactPage .right-bbox form .textarea-wrapper {
      height: 182px;
      overflow: hidden;
      border-bottom: solid 1px rgba(135, 134, 134, 0.5);
      padding: 0px 0px 10px;
      margin-top: 15px; }
      .contactPage .right-bbox form .textarea-wrapper textarea {
        min-height: 169px;
        height: 0;
        overflow: hidden;
        border-bottom: none;
        padding: 0;
        text-rendering: auto;
        -webkit-writing-mode: horizontal-tb !important;
        cursor: text; }
        .contactPage .right-bbox form .textarea-wrapper textarea:focus {
          border: none; }
    .contactPage .right-bbox form .form-group {
      margin-bottom: 0; }
      .contactPage .right-bbox form .form-group .ml {
        margin-left: 7px; }
    .contactPage .right-bbox form .help-block {
      height: 26px;
      color: #e41b5b;
      margin-top: 4px;
      font-size: 0.6875rem;
      letter-spacing: 0.83px;
      font-family: "Noto Sans TC", sans-serif;
      padding: 0 5px; }
    .contactPage .right-bbox form .form-box {
      margin-bottom: 40px; }
    @media (min-width: 992px) {
      .contactPage .right-bbox form .arr_btn:hover::before {
        right: -18px;
        width: 64px; } }
    .contactPage .right-bbox form .arr_btn::before {
      background: #000000;
      right: 0;
      left: auto;
      width: 46px; }
      @media (max-width: 768px) {
        .contactPage .right-bbox form .arr_btn::before {
          width: 36px; } }
    .contactPage .right-bbox form .arr_btn::after {
      background: #000000;
      width: 12px;
      bottom: 4px; }
    .contactPage .right-bbox form .send-btn {
      background: #ffffff;
      border: none;
      position: relative;
      line-height: 0.8;
      margin-bottom: 95px;
      margin-top: 40px;
      padding: 0 15px;
      font-size: 1.125rem;
      font-family: "Noto Sans TC", sans-serif;
      color: #575757;
      cursor: pointer;
      width: 115px;
      text-align: left;
      font-weight: 300; }
      .contactPage .right-bbox form .send-btn:focus {
        outline: none; }
      @media (max-width: 768px) {
        .contactPage .right-bbox form .send-btn {
          width: 105px;
          font-size: 1rem;
          margin-bottom: 20px;
          margin-top: 10px;
          padding: 0 15px; } }

.mCSB_dragger {
  background: #dcdcdc;
  border-radius: 99px; }

.mCSB_dragger_bar {
  background-color: #dcdcdc !important; }

.mCSB_scrollTools {
  width: 8px !important; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #dcdcdc !important;
  width: 1px !important; }

@keyframes blockfade {
  0% {
    opacity: 0;
    top: 10px; }
  100% {
    top: 0;
    opacity: 1; } }

.indexPage .active .in-ltitle {
  -webkit-animation: blockfade .4s  linear forwards;
          animation: blockfade .4s  linear forwards; }

.indexPage .active .ab-box {
  -webkit-animation: blockfade .4s .3s linear forwards;
          animation: blockfade .4s .3s linear forwards; }

.indexPage .active .pro-box {
  -webkit-animation: blockfade .4s .3s linear forwards;
          animation: blockfade .4s .3s linear forwards; }

.indexPage .active .sur-bx {
  -webkit-animation: blockfade .4s linear forwards;
          animation: blockfade .4s linear forwards; }

.indexPage .active .con-bx {
  -webkit-animation: blockfade .4s linear forwards;
          animation: blockfade .4s linear forwards; }

@-webkit-keyframes mfade {
  0% {
    opacity: 0;
    top: 372px; }
  100% {
    top: 362px;
    opacity: 1; } }

@keyframes mfade {
  0% {
    opacity: 0;
    top: 372px; }
  100% {
    top: 362px;
    opacity: 1; } }

@keyframes fade1 {
  0% {
    opacity: 0;
    top: 10px; }
  100% {
    top: 0;
    opacity: 1; } }

@media (max-width: 1200px) {
  .portfolioPage .showmenu {
    -webkit-animation: fade1 .4s linear forwards !important;
            animation: fade1 .4s linear forwards !important; } }

.portfolioPage .showmenu {
  -webkit-animation: mfade .4s  linear forwards;
          animation: mfade .4s  linear forwards; }

.portfolioPage .fade1 {
  -webkit-animation: fade1 .4s .4s linear forwards;
          animation: fade1 .4s .4s linear forwards; }

.portfolioPage .fade2 {
  -webkit-animation: fade1 .4s .8s linear forwards;
          animation: fade1 .4s .8s linear forwards; }

.portfolioPage .active {
  -webkit-animation: fade1 .4s linear forwards;
          animation: fade1 .4s linear forwards; }

.portfolioPage #top-menu-ul .active {
  -webkit-animation: none !important;
          animation: none !important; }
