
.indexPage{
    @keyframes blockfade {
        0%{
            opacity: 0;
            top: 10px;
        }
        100%{
            top: 0;
            opacity:1;
        }
    }
    .active {
        .in-ltitle{
            animation: blockfade .4s  linear forwards;
        }
        .ab-box{
            animation: blockfade .4s .3s linear forwards;
        }
        .pro-box{
            animation: blockfade .4s .3s linear forwards; 
        }
        .sur-bx{
            animation: blockfade .4s linear forwards; 
        }
        .con-bx{
            animation: blockfade .4s linear forwards; 
        }
    }
}

.portfolioPage{
    @keyframes mfade {
        0%{
            opacity: 0;
            top: 372px;
        }
        100%{
            top: 362px;
            opacity:1;
        }
    }
    @keyframes fade1 {
        0%{
            opacity: 0;
            top: 10px;
        }
        100%{
            top: 0;
            opacity:1;
        }
    }
    @media (max-width:1200px) {   
        .showmenu{
            animation: fade1 .4s linear forwards !important;
        }
    }
    .showmenu{
        animation: mfade .4s  linear forwards;
    }
    .fade1{
        animation: fade1 .4s .4s linear forwards;
    }
    .fade2{
        animation: fade1 .4s .8s linear forwards;
    }
    .active{
        animation: fade1 .4s linear forwards;
    }
    #top-menu-ul{
        .active{
            animation: none !important;
        }
    }
}